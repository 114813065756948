import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import './ProgressBar.css'; // Include your updated CSS styles here
import { BASEURL } from '../../Global';
import EmojiIcon from "./../../Assets/images/sun-logo-sunlight-thumbnail.png"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the default styles


import CircularSlider from "react-circular-slider-svg";
import Loader from "../Common/Loader";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const sunIconPosition = {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    width: '24px', // Adjust size as needed
    height: '24px',
};
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        // Extract the name from the payload
        const { name } = payload[0].payload;
        return (
            <div className="custom-tooltip" style={{ color: 'black' }}>
                {name}
            </div>
        );
    }

    return null;
};


const HalfCircleProgressBar = ({ percentage, percentage2, graphic, chartData, dataP, test = false }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        const svgElement = svgRef.current;
        if (svgElement) {
            const circles = svgElement.querySelectorAll('circle');
            circles.forEach(circle => {
                circle.setAttribute('fill', 'url(#imgPattern)');
            });
        }
    }, []);


    const size = 300; // Diameter of the circle
    const value = percentage; // Progress value (0-100%)
    const radius = size / 2; // Radius of the circle
    const strokeWidth = 2; // Width of the stroke

    // Calculate the angle for the progress point (mapping 0-100% to 0-180 degrees)
    const angle = (value / 100) * 180;

    // Convert the angle to radians for trigonometric calculations
    const angleInRadians = (angle - 90) * (Math.PI / 180);

    // Calculate the x and y coordinates for the dot
    const x = radius + (radius - strokeWidth) * Math.cos(angleInRadians);
    const y = radius + (radius - strokeWidth) * Math.sin(angleInRadians);

    // return (
    //     <div style={{ width: size, height: size, position: 'relative', display: 'flex', justifyContent: 'center' }}>
    //         <CircularProgressbar
    //             value={value}
    //             // text={`${value}%`}
    //             circleRatio={0.5}
    //             strokeWidth={strokeWidth}
    //             styles={buildStyles({
    //                 rotation: 1 / 2 + 1 / 4,
    //                 strokeLinecap: "round",
    //                 trailColor: "#eee",
    //                 stroke: '#d6d6d6',
    //                 // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    //                 // strokeLinecap: 'butt',
    //                 // Rotate the trail
    //                 transform: 'rotate(0.25turn)',
    //                 transformOrigin: 'center center',
    //             })}

    //         />
    //         <div
    //             style={{
    //                 position: 'absolute',
    //                 width: '10px', // Adjust size
    //                 height: '10px',
    //                 borderRadius: '50%',
    //                 backgroundColor: 'blue',
    //                 top: `${y}px`,
    //                 left: `${x}px`,
    //                 transform: 'translate(-50%, -50%)',
    //             }}
    //         />


    //         {/* <div className="pie-chart-container">
    //             <PieChart width={500} height={250}>
    //                 <Pie
    //                     data={graphic}
    //                     cx={250}
    //                     cy={125} // Adjusted to fit half-circle
    //                     startAngle={180}
    //                     endAngle={0}
    //                     innerRadius={0}
    //                     outerRadius={102}
    //                     fill="#8884d8"
    //                     paddingAngle={0}
    //                     dataKey="value"
    //                 >
    //                     {
    //                         percentage == 100 ?
    //                             graphic?.map((entry, index) => (
    //                                 <Cell key={`cell-${index}`}
    //                                     // fill={COLORS[index % COLORS.length]}
    //                                     fill={'rgba(255, 255, 143, 0.7)'}

    //                                 />
    //                             ))
    //                             :
    //                             chartData?.graphics?.map((entry, index) => (
    //                                 <Cell key={`cell-${index}`}
    //                                     // fill={COLORS[index % COLORS.length]} 
    //                                     fill={entry?.color}
    //                                 />
    //                             ))

    //                     }
    //                 </Pie>
    //                 <Tooltip content={<CustomTooltip />} />

    //             </PieChart>
    //         </div> */}
    //     </div >
    // );


    // new Finctioanlity
    return (
        <div className={percentage == '100' ? "chndra" : 'sun'} style={{ width: size, height: 200, position: 'relative', display: 'flex', justifyContent: 'center' }}>
            <svg width={0} height={0}>
                <defs>
                    <pattern id="imgPattern" patternUnits="userSpaceOnUse" width="100" height="100">
                        <image href={EmojiIcon} x="0" y="0" width="100" height="100" />
                    </pattern>
                </defs>
            </svg>
            <CircularSlider
                size={size}
                trailColor="white"
                minValue={0}
                maxValue={100}
                startAngle={90}
                endAngle={270}
                angleType={{
                    direction: "cw",
                    axis: "-y",
                }}

                handle1={{
                    value: percentage == 100 ? percentage2 : percentage,
                    // value: percentage == 100 ? 10 : percentage,
                    onChange: (v) => test ? v : '',
                }}
                circle
                arcColor="#1980D0"
                arcBackgroundColor="#fff"
                svgRef={svgRef}
            />
            <div className='main-text'>
                {
                    percentage == 100 ?
                        <>
                            <div>
                                {dataP('Sunset')}({dataP(chartData?.english?.sunset?.split(":")[0])}:{dataP(chartData?.english?.sunset?.split(":")[1])})
                            </div>
                            <div>
                                {dataP('Sunrise')}({dataP(chartData?.english?.sunrise?.split(":")[0])}:{dataP(chartData?.english?.sunrise?.split(":")[1])})
                            </div>
                        </>
                        :
                        <>
                            <div>
                                {dataP('Sunrise')}({dataP(chartData?.english?.sunrise?.split(":")[0])}:{dataP(chartData?.english?.sunrise?.split(":")[1])})
                            </div>
                            <div>
                                {dataP('Sunset')}({dataP(chartData?.english?.sunset?.split(":")[0])}:{dataP(chartData?.english?.sunset?.split(":")[1])})
                            </div>

                        </>
                }
            </div>
            <div className="pie-chart-container-2">
                <PieChart width={500} height={250}>
                    <Pie
                        data={graphic}
                        cx={250}
                        cy={125} // Adjusted to fit half-circle
                        startAngle={180}
                        endAngle={0}
                        innerRadius={0}
                        outerRadius={102}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                    >
                        {
                            percentage == 100 ?
                                graphic?.map((entry, index) => (
                                    <Cell key={`cell-${index}`}
                                        // fill={COLORS[index % COLORS.length]}
                                        fill={'rgba(255, 255, 143, 0.7)'}

                                    />
                                ))
                                :
                                chartData?.graphics?.map((entry, index) => (
                                    <Cell key={`cell-${index}`}
                                        // fill={COLORS[index % COLORS.length]} 
                                        fill={entry?.color}
                                    />
                                ))

                        }
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />

                </PieChart>
            </div>
        </div>
    );



};

const ProgressBar = ({ dataP }) => {
    const barRef = useRef(null);
    const timeoutRef = useRef(null);
    const timeoutRefChandra = useRef(null);
    const [percentage, setPercentage] = useState();
    const [percentage2, setPercentage2] = useState();
    const [date, setDate] = useState(new Date());
    const [graphic, setGraphic] = useState([]);
    const [chartData, setChartData] = useState({});
    const [graphic2, setGraphic2] = useState([]);
    const [chartData2, setChartData2] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const { rotate } = useSpring({
        from: { rotate: 45 },
        to: percentage == 100 ? { rotate: 45 + (percentage2 * 1.8) } : { rotate: 45 + (percentage * 1.8) },
        config: { duration: 3000 },
    });
    useEffect(() => {
        // getChandraData(date);
        getData(date);
        // Update animation if percentage changes
        if (percentage == 100) {
            // console.log("liveChandraTime2")
            rotate.set(45 + (percentage2 * 1.8));
        } else {
            // console.log("liveSunTime2")
            rotate.set(45 + (percentage * 1.8));
        }

    }, [percentage, rotate, percentage2]);
    useEffect(() => {
        if (chartData) {
            if (percentage == 100) {
                liveChandraTime();
                // console.log("liveChandraTime")
                return () => {
                    // Clear the timeout if the component unmounts
                    clearTimeout(timeoutRefChandra.current);
                };
            } else {
                liveSunTime();
                // console.log("liveSunTime")
                return () => {
                    // Clear the timeout if the component unmounts
                    clearTimeout(timeoutRef.current);
                };
            }
        }
    }, [chartData]);

    const liveSunTime = () => {
        const now = new Date();

        const hours = now.getHours();
        const minutes = now.getMinutes();

        const totalMinutes = hours * 60 + minutes;
        let sunrisePercentage = (chartData?.progress?.sunrise_minutes * 100) / 1440;
        let sunsetPercentage = (chartData?.progress?.sunset_minutes * 100) / 1440;
        let currentMinutePercentage = (totalMinutes * 100) / 1440;

        if (currentMinutePercentage >= sunsetPercentage) {
            setPercentage(100);
        } else if (currentMinutePercentage < sunrisePercentage) {
            // setPercentage(0);
            setPercentage(100);
        } else {
            const dayRangePercentage = sunsetPercentage - sunrisePercentage;
            const elapsedPercentage = currentMinutePercentage - sunrisePercentage;
            const percentage = (elapsedPercentage / dayRangePercentage) * 100;
            setPercentage(percentage);
        }

        // Call the function again after 1 second
        timeoutRef.current = setTimeout(liveSunTime, 1000);
        // console.log("liveSunTime3", sunrisePercentage, sunsetPercentage, currentMinutePercentage)
    };
    const liveChandraTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        const totalMinutes = hours * 60 + minutes;

        const sunriseMinutes = chartData?.progress?.sunrise_minutes;
        const sunsetMinutes = chartData?.progress?.sunset_minutes;
        // const sunsetMinutes = 500;

        // Calculate the day range and night range
        const dayDuration = sunsetMinutes - sunriseMinutes;
        const nightDuration = (sunriseMinutes + 1440 - sunsetMinutes) % 1440;
        if (totalMinutes < sunsetMinutes) {
            // If current time is before sunset
            setPercentage2(0);
        }
        // else if (totalMinutes > sunriseMinutes) {
        //     // If current time is after sunrise
        //     console.log('percentage2')
        //     setPercentage2(100);
        // } 
        else {
            // Calculate the percentage based on the night time (spanning two days)
            if (totalMinutes >= sunsetMinutes || totalMinutes < sunriseMinutes) {
                // Nighttime calculation (from sunset to next sunrise)
                const minutesAfterSunset = (totalMinutes - sunsetMinutes + 1440) % 1440;
                const percentage = (minutesAfterSunset * 100) / nightDuration;
                setPercentage2(percentage);
            } else {
                // Daytime calculation (from sunrise to sunset)
                // const elapsedDayMinutes = totalMinutes - sunriseMinutes;
                // const percentage = (elapsedDayMinutes * 100) / dayDuration;
                // setPercentage2(percentage);
            }
        }

        // Call the function again after 1 second
        timeoutRefChandra.current = setTimeout(liveChandraTime, 10000);
        // console.log("test")
    };

    const getData = async (date) => {
        // setIsLoading(true)
        const Token = await localStorage.getItem("authToken")
        const Latitude = await localStorage.getItem("saralLatitude")
        const Longitude = await localStorage.getItem("saralLongitude")
        const CountryCode = await localStorage.getItem("saralCountry")
        const language = await localStorage.getItem("selectedLanguage")
        const year = new Date(date).getFullYear();
        const month = new Date(date).getMonth() + 1;
        const day = new Date(date).getDate();

        try {
            await fetch(`${BASEURL}/calculationfromformulasv2`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        latitude: Latitude.toString(),
                        longitude: Longitude.toString(),
                        country_code: CountryCode.toString(),
                        day: day.toString(),
                        month: month.toString(),
                        year: year.toString(),
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            // Get the current date and time

                            setChartData(responseJson.data)
                            const gData = responseJson.data?.graphics?.map((data) => {
                                const gujName = Object.keys(responseJson.data.gujarati).find(key => responseJson.data.gujarati[key] === data.time);
                                const HinName = Object.keys(responseJson.data.hindi).find(key => responseJson.data.hindi[key] === data.time);
                                return {
                                    // name: data?.name,
                                    name: language == "Gujarati" ? gujName : language == "Hindi" ? HinName : data?.name,
                                    value: data?.percentage,
                                    color: data?.color
                                }
                            })
                            const dataChandra = [
                                {
                                    name: '',
                                    value: 100,
                                    color: 'rgba(255, 255, 143, 0.7)'

                                },
                            ];
                            percentage == 100 ? setGraphic(dataChandra) : setGraphic(gData)
                            setIsLoading(false)
                        } else {
                            // toastr.warning("Something Went Wrong !!")
                            setIsLoading(false)
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    const getChandraData = async (date) => {
        const Token = await localStorage.getItem("authToken")
        const Latitude = await localStorage.getItem("saralLatitude")
        const Longitude = await localStorage.getItem("saralLongitude")
        const CountryCode = await localStorage.getItem("saralCountry")
        const language = await localStorage.getItem("selectedLanguage")
        const year = new Date(date).getFullYear();
        const month = new Date(date).getMonth() + 1;
        const nextDay = new Date(date);
        nextDay.setDate(date.getDate() + 1);
        const day = nextDay.getDate();


        try {
            await fetch(`${BASEURL}/calculationfromformulasv2`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        latitude: Latitude.toString(),
                        longitude: Longitude.toString(),
                        country_code: CountryCode.toString(),
                        day: day.toString(),
                        month: month.toString(),
                        year: year.toString(),
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            // Get the current date and time

                            setChartData2(responseJson.data)
                            const gData = responseJson.data?.graphics?.map((data) => {
                                const gujName = Object.keys(responseJson.data.gujarati).find(key => responseJson.data.gujarati[key] === data.time);
                                const HinName = Object.keys(responseJson.data.hindi).find(key => responseJson.data.hindi[key] === data.time);
                                return {
                                    // name: data?.name,
                                    name: language == "Gujarati" ? gujName : language == "Hindi" ? HinName : data?.name,
                                    value: data?.percentage,
                                    color: data?.color
                                }
                            })
                            setGraphic2(gData)
                        } else {
                            // toastr.warning("Something Went Wrong !!")
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    return (
        <div>
            {/* <div className="progress-container">
                <div style={{ position: "relative" }}>
                    <div className="progress">
                        <div className="barOverflow">
                            <animated.div
                                ref={barRef}
                                className="bar"
                                style={{ transform: rotate.to((r) => `rotate(${r}deg)`) }}
                            />

                            <animated.div
                                className="marker"
                                style={{
                                    transform: rotate.to(
                                        (r) => `rotate(${r - 45}deg) translateX(-1px)` // Adjust for marker positioning
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className='main-text'>
                        {
                            percentage == 100 ?
                                <>
                                    <div>
                                        {dataP('Sunset')}({dataP(chartData?.english?.sunset?.split(":")[0])}:{dataP(chartData?.english?.sunset?.split(":")[1])})
                                    </div>
                                    <div>
                                        {dataP('Sunrise')}({dataP(chartData?.english?.sunrise?.split(":")[0])}:{dataP(chartData?.english?.sunrise?.split(":")[1])})
                                    </div>
                                </>
                                :
                                <>
                                    <div>
                                        {dataP('Sunrise')}({dataP(chartData?.english?.sunrise?.split(":")[0])}:{dataP(chartData?.english?.sunrise?.split(":")[1])})
                                    </div>
                                    <div>
                                        {dataP('Sunset')}({dataP(chartData?.english?.sunset?.split(":")[0])}:{dataP(chartData?.english?.sunset?.split(":")[1])})
                                    </div>

                                </>
                        }
                    </div>
                </div>
                <div className="pie-chart-container">
                    <PieChart width={500} height={250}>
                        <Pie
                            data={graphic}
                            cx={250}
                            cy={125} // Adjusted to fit half-circle
                            startAngle={180}
                            endAngle={0}
                            innerRadius={0}
                            outerRadius={102}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                        >
                            {
                                percentage == 100 ?
                                    graphic?.map((entry, index) => (
                                        <Cell key={`cell-${index}`}
                                            // fill={COLORS[index % COLORS.length]}
                                            fill={'rgba(255, 255, 143, 0.7)'}

                                        />
                                    ))
                                    :
                                    chartData?.graphics?.map((entry, index) => (
                                        <Cell key={`cell-${index}`}
                                            // fill={COLORS[index % COLORS.length]} 
                                            fill={entry?.color}
                                        />
                                    ))

                            }
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />

                    </PieChart>
                </div>
            </div> */}
            <Loader isLoading={isLoading} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <HalfCircleProgressBar graphic={graphic} chartData={chartData} percentage={percentage} percentage2={percentage2} dataP={dataP} />
            </div>
        </div>
    );
};

export default ProgressBar;

import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        const isLogin = localStorage.getItem('authUserJain')
        if (isLogin === "true") {
            this.setState({ isLogin: true })
        } else {
            this.setState({ isLogin: false })
        }

    }

    render() {
        return (
            <React.Fragment>
                <div className="ast_service_wrapper  ast_bottompadder50" style={{ zIndex: 9, position: 'relative' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_heading">
                                    <h1>our <span>services</span></h1>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by Lorem Ipsum.</p> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/navkarshi" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/navkarashi.png')} alt="Service" />
                                        <h4>Navkarshi</h4>
                                        {/* <p style={{ color: "#000" }}> Location-Based Timings: Receive personalized data based on your location, ensuring accurate and relevant information tailored as per your location. Get daily Sunrise, Sunset &amp; other pachkan timings accurately as per your location. </p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/pachkhan" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/pachkhan.png')} alt="Service" />
                                        <h4>Pachkhan</h4>
                                        {/* <p style={{ color: "#000" }}> refers to a division of time associated with planetary influences. It is used to divide the day and night into 24 parts, with each "Hora" ruled by a different planet..</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/choghadiya" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/chogadiya.png')} alt="Service" />
                                        <h4>Choghadiya</h4>
                                        {/* <p style={{ color: "#000" }}> is a traditional Hindu system for determining auspicious and inauspicious times during a day for various activities.</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/panchkalyanak" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/panch_kalyanak.png')} alt="Service" />
                                        <h4>Panch Kalyanak</h4>
                                        {/* <p style={{ color: "#000" }}>is a significant concept in Jainism, representing five auspicious life events in the life of Tirthankaras (spiritual leaders).</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/jainfestival" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/jain-festival.png')} alt="Service" />
                                        <h4>Jain Festival</h4>
                                        {/* <p style={{ color: "#000" }}> refers to a division of time associated with planetary influences. It is used to divide the day and night into 24 parts, with each "Hora" ruled by a different planet..</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/extranotes" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/extra-notes.png')} alt="Service" />
                                        <h4>Extra Notes</h4>
                                        {/* <p style={{ color: "#000" }}> refers to a division of time associated with planetary influences. It is used to divide the day and night into 24 parts, with each "Hora" ruled by a different planet..</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/suryarashi" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/surya_rashi.png')} alt="Service" />
                                        <h4>Surya Rashi</h4>
                                        {/* <p style={{ color: "#000" }}>is a term used in Indian astrology to refer to one's zodiac sign or sun sign. Here are ten one-word descriptions for each of the twelve Surya Rashis.</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/chandrarashi" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/chandra_rashi.png')} alt="Service" />
                                        <h4>Chandra Rashi</h4>
                                        {/* <p style={{ color: "#000" }}>also known as one's Moon sign in Vedic astrology, represents the zodiac sign where the Moon was positioned at the time of a person's birth. </p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/hora" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/hora.png')} alt="Service" />
                                        <h4>Hora</h4>
                                        {/* <p style={{ color: "#000" }}> refers to a division of time associated with planetary influences. It is used to divide the day and night into 24 parts, with each "Hora" ruled by a different planet..</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                             <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/suryanakshtra" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/suraya-nakshtra-2.png')} alt="Service" />
                                        <h4>Surya Nakshtra</h4>
                                        {/* <p style={{ color: "#000" }}>is a term used in Indian astrology to refer to one's zodiac sign or sun sign. Here are ten one-word descriptions for each of the twelve Surya Rashis.</p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                             <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ cursor: 'pointer' }}>
                                <Link to={this.state.isLogin ? process.env.PUBLIC_URL + "/chandranakshtra" : process.env.PUBLIC_URL + "/login"}>
                                    <div className="ast_service_box">
                                        <img style={{ maxHeight: 84 }} src={require('../../Assets/images/Icon/chandra-nakshtra-2.png')} alt="Service" />
                                        <h4>Chandra Nakshtra</h4>
                                        {/* <p style={{ color: "#000" }}>also known as one's Moon sign in Vedic astrology, represents the zodiac sign where the Moon was positioned at the time of a person's birth. </p> */}
                                        <div className="clearfix"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Services);

import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Download from "../../Pages/Common/Download";
import MainHeader from "../../Pages/Common/Mainheader";
import Breadcrumb from "../../Pages/Common/Breadcrumb";
import Features from "../../Pages/Common/Features";


class FeaturesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                <Breadcrumb title="Our Features" />
                <Features />
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default FeaturesPage;

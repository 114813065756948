import React, { useEffect } from "react";
import { Navigate, Outlet, } from "react-router-dom";

function PrivateRoute({ }) {
    let isProtected = localStorage.getItem('authUserJain')

    useEffect(() => {
        checkAuth()
    }, [])

    async function checkAuth() {
        if (isProtected === "true") {
            isProtected = true
        }
        else {
            isProtected = false
        }
    }

    return isProtected ? <Outlet /> : <Navigate to={process.env.PUBLIC_URL + "/"} />;
}

export default PrivateRoute;

import React, { Component } from 'react';
import { Button, Label, } from "reactstrap";

// import images
import "flatpickr/dist/themes/material_blue.css"
import "../../Pages/css/_authentication.css"
import Select from "react-select";

import "../Authorization/login.css"
import { withNamespaces } from 'react-i18next';

import 'toastr/build/toastr.min.css'
import i18n from '../../i18n';
import Loader from './Loader';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL, getcountrycode } from '../../Global';

class Preferences extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: "",
            selectedGach: "1tithi",
            tithi: "",
            geoLocation:"",  
            selectedCalendarType: "",
            latitude: "",
            longitude: "",
            country_code: "",
            userData: [],
            showLoader: false
        }
        this.interval = null;
    }

    async componentDidMount() {
        this.getuser()
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
        // this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds
    }

    getLoaction() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.handleSuccess,
                this.handleError
            );
        } else {
            toastr.error("Geolocation is not supported by your browser")
            this.setState({ error: 'Geolocation is not supported by your browser' });
        }
    }

    handleSuccess = async (position) => {
        if (position) {
            const con = await getcountrycode()
            // localStorage.setItem("saralLongitude", position.coords.longitude)
            // localStorage.setItem("saralLatitude", position.coords.latitude)
            // localStorage.setItem("saralCountry", con.countrycode)
            this.setState({
                latitude: position.coords?.latitude,
                longitude: position.coords?.longitude,
                country_code: con.countrycode
            });
        } else {
            // console.log("Not 2")
        }
    };

    handleError = () => {
        toastr.error("Please Allow Location ..")
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }

    async getuser() {
        this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/findtenantusersbyid/${userID}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            // console.log("Respionse JSON", responseJson);

                            this.setState({
                                userData: responseJson.data,
                                selectedLanguage: responseJson.data.language,
                                tithi: responseJson.data.tithi,
                                geoLocation: responseJson.data.geo_location,
                                selectedCalendarType: responseJson.data.calendar_setting,
                                showLoader: false
                            })
                            // console.log("console log data", this.state.userData)
                            await this.changeLanguageAction(responseJson.data.language)
                        } else {
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async userProfile() {
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")
        // console.log("Form data", JSON.stringify({
        //     "language": this.state.selectedLanguage,
        //     "calendar_setting": this.state.selectedCalendarType,
        //     "latitude": this.state.latitude ? this.state.latitude : this.state.userData.latitude,
        //     "longitude": this.state.longitude ? this.state.longitude : this.state.userData.longitude,
        //     "country_code": this.state.country_code ? this.state.country_code : this.state.userData.country_code,
        // }))
        this.setState({ showLoader: true })
        try {
            await fetch(`${BASEURL}/updatetenantusers/${userID}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "language": this.state.selectedLanguage,
                        "calendar_setting": this.state.selectedCalendarType,
                        "geo_location": this.state.geoLocation,
                        "tithi": this.state.tithi,
                        "latitude": this.state.latitude ? this.state.latitude : this.state.userData.latitude,
                        "longitude": this.state.longitude ? this.state.longitude : this.state.userData.longitude,
                        "country_code": this.state.country_code ? this.state.country_code : this.state.userData.country_code,
                    })
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            // this.props.history.push(process.env.PUBLIC_URL + '/login')
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + "/login"
                            return false;
                        }
                        if (responseJson.data) {
                            toastr.success("User Preference Updated Succesfully")

                            localStorage.setItem("saralLongitude", this.state.longitude ? this.state.longitude : this.state.userData.longitude,)
                            localStorage.setItem("saralLatitude", this.state.latitude ? this.state.latitude : this.state.userData.latitude)
                            localStorage.setItem("saralCountry", this.state.country_code ? this.state.country_code : this.state.userData.country_code,)

                            await this.changeLanguageAction(this.state.selectedLanguage)
                            this.getuser()
                            this.setState({ showLoader: false })
                        } else {
                            toastr.warning("Something Went Wrong !!")
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
    }

    render() {
        const optionLanguage = [
            { label: "Hindi", value: "Hindi" },
            { label: "English", value: "English" },
            { label: "Gujarati", value: "Gujarati" },
        ];

        const optionCalendar = [
            { label: "English (Gregorian)", value: "Gregorian" },
            { label: "Jain (Hindu)", value: "Hindu" },
        ];
        const tithiList = [
            { label: "5", value: "5" },
            { label: "12", value: "12" },
        ];

        const optionGach = [
            { label: "1 Tithi", value: "1tithi" },
        ];
        const geoLocationList = [
            { label: "Auto", value: "auto " },
            { label: "Manual", value: "manual" },
        ];

        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className='ast_toppadder70 ast_bottompadder20 custom-bg-login'>
                    <div className="ast_toppadder70 ast_bottompadder70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3">
                                        <Label className="form-label"> Languages</Label>
                                        <Select
                                            value={optionLanguage.find(option => option.value === this.state.selectedLanguage)}
                                            onChange={(e) => this.setState({ selectedLanguage: e.value })}
                                            options={optionLanguage}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3">
                                        <Label className="form-label"> Calendar Type</Label>
                                        <Select
                                            value={optionCalendar.find(option => option.value === this.state.selectedCalendarType)}
                                            onChange={(e) => this.setState({ selectedCalendarType: e.value })}
                                            options={optionCalendar}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3">
                                        <Label className="form-label"> Gachha</Label>
                                        <Select
                                            value={optionGach.find(option => option.value === this.state.selectedGach)}
                                            onChange={(e) => this.setState({ selectedGach: e.value })}
                                            options={optionGach}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div className="">
                                        <Label className="form-label"> Tithi</Label>
                                        <Select
                                            value={tithiList.find(option => option.value === this.state.tithi)}
                                            onChange={(e) => this.setState({ tithi: e.value })}
                                            options={tithiList}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="">
                                        <Label className="form-label"> Geo Location</Label>
                                        <Select
                                            value={geoLocationList.find(option => option.value === this.state.geoLocation)}
                                            onChange={(e) => this.setState({ geoLocation: e.value })}
                                            options={geoLocationList}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <Label className="form-label"> Change Location</Label>
                                    <div className='card'>
                                        {this.state.userData &&
                                            <div style={{ marginHorizontal: 20, backgroundColor: "#fff", borderRadius: 10, padding: 10 }}>
                                                <p className='m-0' style={{ color: "#000" }}>{`"Latitude :"${this.state.userData?.latitude}"`}</p>
                                                <p className='m-0' style={{ color: "#000" }}>{`"Longitude :"${this.state.userData?.longitude}"`}</p>
                                                <p className='m-0' style={{ color: "#000" }}>{`"Country : "${this.state.userData?.country_code}"`}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-4">
                                    {this.state.latitude &&
                                        <>
                                            <Label className="form-label text-success fw-bold"> New Current Location</Label>
                                            <div className='card'>
                                                {this.state.latitude &&
                                                    <div style={{ marginHorizontal: 20, backgroundColor: "#fff", borderRadius: 10, padding: 10 }}>
                                                        <p className='m-0' style={{ color: "#000" }}>{`"Latitude :"${this.state.latitude}"`}</p>
                                                        <p className='m-0' style={{ color: "#000" }}>{`"Longitude :"${this.state.longitude}"`}</p>
                                                        <p className='m-0' style={{ color: "#000" }}>{`"Country :"IN"`}</p>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {!this.state.latitude &&
                                        <a onClick={() => this.getLoaction()} className="ast_btn mt-4 w-100">Get Your Current Location</a>
                                        // <Button color="primary" className="w-md waves-effect waves-light" onClick={() => this.getLoaction()}>Get Your Current Location</Button>
                                    }
                                </div>
                                <div style={{ textAlign: 'end', }}>
                                    <a style={{ width: "25%" }} onClick={() => this.userProfile()} className="ast_btn mt-2">Submit</a>
                                    {/* <Button color="primary" className="w-50 waves-effect waves-light mt-2" onClick={() => this.userProfile()}>Submit</Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Preferences);
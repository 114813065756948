import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Download from "../../Pages/Common/Download";
import MainHeader from "../../Pages/Common/Mainheader";
import Breadcrumb from "../../Pages/Common/Breadcrumb";
import GetinTouch from "../../Pages/Common/GetinTouch";
import ContactForm from "../../Pages/ContactForm/ContactForm";


class ContactUS extends Component {
    constructor(props) {
        super(props);
        this.state = {


        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                <Breadcrumb title="Contact US" />
                <GetinTouch />
                <ContactForm />
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default ContactUS;

import React, { Component } from "react";
import {
    UncontrolledCarousel,
} from 'reactstrap';
import Loader from "../Common/Loader";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL } from "../../Global";

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.getSlider = this.getSlider.bind(this);
    }


    componentDidMount() {
        this.getSlider()
    }

    async getSlider() {
        this.setState({ isLoading: true })
        var Token = await localStorage.getItem("authToken")
        try {
            await fetch(`${BASEURL}/getallsliderfrontend`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson) {
                            if (responseJson.message === "An Exception occurred while trying to authenticate the User.") {
                                localStorage.clear()
                                // window.location.href = process.env.PUBLIC_URL + '/login';
                                this.props.history.push(process.env.PUBLIC_URL + '/login')
                                return false;
                            }
                            if (responseJson.data) {
                                let sliderData = responseJson.data
                                let newArr = []
                                sliderData?.map((item, index) => {
                                    newArr.push({ altTest: null, caption: item.description, key: index + 1, src: item.image })
                                })
                                // console.log("NewArr", newArr)
                                this.setState({
                                    slidersData: newArr,
                                    isLoading: false
                                })
                                // console.log("Resopnse data Slider", responseJson)
                            }
                        } else {
                            this.setState({ isLoading: false })
                            toastr.warning("Something Went Wrong !!")
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isLoading} />
                <div className="">
                    {this.state.slidersData &&
                        <UncontrolledCarousel
                            style={{ imageOrientation: 'revert-layer' }}
                            className="ast_slider_wrapper"
                            // style={{ height: 620 }}
                            fade={true}
                            items={this.state.slidersData}
                        />
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default Slider;

import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        const { title } = this.props;
        return (
            <React.Fragment>
                <div className="ast_pagetitle">
                    <div className="ast_img_overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="page_title">
                                    <h2>{`${title}`}</h2>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <ul className="breadcrumb">
                                    <li><a href={process.env.PUBLIC_URL + "/"}>home</a></li>
                                    <li style={{ color: "#000" }}>/</li>
                                    <li><a href="#">{`${title}`}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Breadcrumb);

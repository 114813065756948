import React, { Component } from "react";
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_orange.css"
import "../../../Components/css/navkarshi.css"
import { Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import Loader from "../Loader";
import i18n from "../../../i18n";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL, getcountrycode } from "../../../Global";

class Navkarshi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputDate: new Date(),
            navkarshiData: [],
            selectedType: "Day",
            showLoader: false
        };
        this.interval = null;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getNavkarshi(this.state.inputDate)

        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
        // this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds
    }
    async getNavkarshi(date) {
        // console.log("date", date, this.state.inputDate)
        this.setState({ showLoader: true, inputDate: date })
        const Token = await localStorage.getItem("authToken")
        const Longitude = await localStorage.getItem("saralLongitude")
        const Latitude = await localStorage.getItem("saralLatitude")
        const Country = await localStorage.getItem("saralCountry")

        const year = new Date(`${date}`).getFullYear();
        const month = new Date(`${date}`).getMonth() + 1;
        const day = new Date(`${date}`).getDate();
        // console.log("Form data", JSON.stringify({
        //     "latitude": Latitude,
        //     "longitude": Longitude,
        //     "country_code": Country,
        //     "day": day,
        //     "month": month,
        //     "year": year
        // }))

        try {
            await fetch(`${BASEURL}/calculationfromformulas`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "latitude": Latitude,
                        "longitude": Longitude,
                        "country_code": Country,
                        "day": day,
                        "month": month,
                        "year": year
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data?.english) {
                            this.setState({ navkarshiData: responseJson.data, showLoader: false, inputDate: date })
                            // this.props.navigation.navigate("LoginOTP", { mobile: responseJson.data.mobile })
                        } else {
                            toastr.warning("Something Went Wrong !!")
                            //  
                            this.setState({ showLoader: false, navkarshiData: null, })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }
    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder40 bg-light">
                    <div className="container">
                        <div className="row ast_bottompadder30" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                            <Col onClick={() => this.getNavkarshi(new Date(new Date(this.state.inputDate).setDate(this.state.inputDate.getDate() - 1)))} sm="auto" style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, backgroundColor: "#3b3483", color: "#fff" }}>
                                <div className="form-group my-2 arrowleft">
                                    <i className="fa fa-chevron-left" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                                </div>
                            </Col>
                            <Col sm="auto" className="" style={{ backgroundColor: "#fff" }}>
                                <div className="form-group my-2">
                                    <div className="input-group">
                                        <Flatpickr
                                            key={this.state.inputDate}
                                            value={this.state.inputDate}
                                            onChange={(e) => {
                                                this.setState({ inputDate: new Date(`${e}`) })
                                                this.getNavkarshi(new Date(`${e}`))
                                            }}
                                            className="form-control d-block text-center w-130px"
                                            placeholder="dd M,yyyy"
                                            options={{
                                                dateFormat: "d-m-Y"
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm="auto" onClick={() => this.getNavkarshi(new Date(new Date(this.state.inputDate).setDate(this.state.inputDate.getDate() + 1)))} style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, backgroundColor: "#3b3483", color: "#fff" }}>
                                <div className="form-group my-2">
                                    <i className="fa fa-chevron-right" style={{ fontSize: 32, marginTop: 3.5 }}></i>
                                </div>
                            </Col>
                            <Col sm="auto" onClick={() => this.getNavkarshi(new Date())} style={{ borderRadius: 10, backgroundColor: "#3b3483", color: "#fff", marginLeft: 10 }}>
                                <div className="form-group" style={{ marginBlockStart: 10, marginBlockEnd: 12 }}>
                                    <span className="mx-2" style={{ fontSize: 18 }}>Today</span>
                                    {/* <i  className="fa fa-chevron-right" style={{ fontSize: 32, marginTop: 3.5 }}></i> */}
                                </div>
                            </Col>
                        </div>
                        {this.state.navkarshiData?.english ?
                            <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 15, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', }}>
                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Sunrise`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.sunrise?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.sunrise?.split(":")[1]}`)}</Col>
                                </div>
                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Sunset`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.sunset?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.sunset?.split(":")[1]}`)}</Col>
                                </div>
                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Navkarshi`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.navkarshi?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.navkarshi?.split(":")[1]}`)}</Col>
                                </div>
                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Porshi`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.porisi?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.porisi?.split(":")[1]}`)}</Col>
                                </div>

                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Sadha Porshi`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.saddha_porisi?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.saddha_porisi?.split(":")[1]}`)}</Col>
                                </div>
                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Purnimaddha`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.purimaddha?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.purimaddha?.split(":")[1]}`)}</Col>
                                </div>
                                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2 }} className="row mx-2 p-3">
                                    <Col className="text-center fw-bold ">{this.props.t(`Avadha`)}</Col>
                                    <Col className="text-center fw-bold col-1">|</Col>
                                    <Col className="text-center fw-bold ">{this.props.t(`${this.state.navkarshiData?.english?.avaddha?.split(":")[0]}`) + ":" + this.props.t(`${this.state.navkarshiData?.english?.avaddha?.split(":")[1]}`)}</Col>
                                </div>
                            </div> :
                            <div style={{ justifyContent: 'center', width: "100%", height: 150, textAlign: 'center', display: 'flex' }}>
                                <h2 style={{ margin: 'auto' }}>{this.props.t(`No Data Found !`)}</h2>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Navkarshi);

import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Loader from "./Loader";

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 500);
    }

    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isLoading} />
                <div class="ast_vastu_wrapper ast_toppadder70 ast_bottompadder70">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="ast_vastu_info">
                                    {/* <h3>Our Features</h3> */}
                                    <p>Saral Jain Panchang, your guide through Jain auspicious events, provides an intuitive, ad-free experience with exact location-based pachkan timings, multilingual options, and insights into Rashi, Kalyanak, Festivals, and Other Jain events. Simplify your Jain calendar journey with Saral Jain Panchang.</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <img src={require("../../Assets/images/about-img.png")} alt="About" />
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <ul>
                                                <li><b>Location-Based Timings:</b> Receive personalized data based on your location, ensuring accurate and relevant information tailored as per your location. Get daily Sunrise, Sunset &amp; other pachkan timings accurately as per your location.</li>
                                                <li><b>Multilingual Interface:</b> Choose between English, Hindi, and Gujarati languages, making Saral Jain Panchang accessible to a diverse range of users.</li>
                                                <li><b>Surya Rashi and Chandra Rashi: </b>Get to know the exact Surya & Chandra Rashi of any particular day from given calendar range.</li>
                                                <li><b>Panch Kalyanak: </b>Get to know the Auspicious dates of all Kalyanaks of our Tirthankaras.</li>
                                                <li><b>Festival Timings: </b>You can get the exact dates of all the important Jain Festivals from the given calendar range.</li>
                                                <li><b>Memorable Events: </b>Stay informed about significant events like death anniversaries or birth anniversaries of many revered Jain Acharyas.</li>
                                                <li><b>Ad-free Experience: </b>There are many good Jain Panchang apps. But almost all of them are flooded with Ads & there is a paywall to unlock the premium experience. We will make sure that our platform remains ad-free & no Paywalls at all..</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Features)
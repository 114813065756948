import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import i18n from "../../i18n";
import $ from "jquery";

let counter = 0
class MainHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolledPast300px: false,
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const isLogin = localStorage.getItem('authUserJain')
        if (isLogin === "true") {
            this.setState({ isLogin: true })
        } else {
            this.setState({ isLogin: false })
        }

        $(document).ready(function () {
            var w = window.innerWidth;
            if (w <= 991) {
                $(".ast_menu > ul > li").find("a").click(function () {
                    $(this).parent().siblings().children(".submenu").slideUp();
                    $(this).parent().children(".submenu").slideDown();
                });
            }
        });

    }

    handleNavbar = () => {
        $('.ast_menu_btn').on("click", function () {
            if (counter === 0) {
                $('.ast_main_menu_wrapper').addClass('ast_main_menu_hide');
                $(this).children().removeAttr('class');
                $(this).children().attr('class', 'fa fa-close');
                counter++;
            }
            else {
                $('.ast_main_menu_wrapper').removeClass('ast_main_menu_hide');
                $(this).children().removeAttr('class');
                $(this).children().attr('class', 'fa fa-bars');
                counter--;
            }

        });
    }

    handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY >= 150 && !this.state.scrolledPast300px) {
            this.setState({ scrolledPast300px: true });
        } else if (scrollY < 150 && this.state.scrolledPast300px) {
            this.setState({ scrolledPast300px: false });
        }
    };
    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const menuClass = this.state.scrolledPast300px ? 'ast_header_bottom menu_fixed animated fadeInDown' : 'ast_header_bottom';
        return (
            <React.Fragment>
                <div className={menuClass}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-10">
                                <div className="ast_logo">
                                    <a href={this.state.isLogin ? process.env.PUBLIC_URL + "/user-dashboard" : process.env.PUBLIC_URL + "/"}><img className="customimg" src={require('../../Assets/images/Logo/logo.png')} alt="Logo" title="Logo" /></a>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-2 position-relative">
                                <div className="ast_main_menu_wrapper">
                                    <div className="ast_menu">
                                        <ul>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/user-dashboard" : process.env.PUBLIC_URL + "/"} style={{ color: this.state.scrolledPast300px ? "auto" : "auto" }}>home</a></li>
                                            {/* <li className="as_submenu_li"><a className="dropdown-toggle" style={{ color: this.state.scrolledPast300px ? "auto" : "auto" }} href="#">Languages</a>
                                                <ul className="submenu dropdown">
                                                    <li onClick={() => this.changeLanguageAction("English")}><a href="#">English</a></li>
                                                    <li onClick={() => this.changeLanguageAction("Gujarati")}><a href="#">Gujarati</a></li>
                                                    <li onClick={() => this.changeLanguageAction("Hindi")}><a href="#">Hindi</a></li>
                                                </ul>
                                            </li> */}
                                            <li className="as_submenu_li"><a className="dropdown-toggle" style={{ color: this.state.scrolledPast300px ? "auto" : "auto" }} href="#">services</a>
                                                <ul className="submenu dropdown">
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/navkarshi" : process.env.PUBLIC_URL + "/login"}>Navkarshi</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/pachkhan" : process.env.PUBLIC_URL + "/login"}>Pachkhan</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/choghadiya" : process.env.PUBLIC_URL + "/login"}>Choghadiya</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/panchkalyanak" : process.env.PUBLIC_URL + "/login"}>Panch Kalyanak</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/jainfestival" : process.env.PUBLIC_URL + "/login"}>Jain Festival</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/extranotes" : process.env.PUBLIC_URL + "/login"}>Extra notes</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/suryarashi" : process.env.PUBLIC_URL + "/login"}>Surya Rashi</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/chandrarashi" : process.env.PUBLIC_URL + "/login"}>Chandra Rashi</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/suryanakshtra" : process.env.PUBLIC_URL + "/login"}>Surya Nakshtra</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/chandranakshtra" : process.env.PUBLIC_URL + "/login"}>Chandra Nakshtra</a></li>
                                                    <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/hora" : process.env.PUBLIC_URL + "/login"}>Hora</a></li>
                                                    {/* <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/calender-pdf" : process.env.PUBLIC_URL + "/login"}>Calender PDF</a></li> */}
                                                </ul>
                                            </li>
                                            <li><a href={process.env.PUBLIC_URL + "/features"} style={{ color: this.state.scrolledPast300px ? "auto" : "auto" }}>Features</a></li>
                                            <li><a href={process.env.PUBLIC_URL + "/about"} style={{ color: this.state.scrolledPast300px ? "auto" : "auto" }}>about</a></li>
                                            <li><a href={process.env.PUBLIC_URL + "/contactus"} style={{ color: this.state.scrolledPast300px ? "auto" : "auto" }}>contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <button onClick={this.handleNavbar} className="ast_menu_btn"><i className="fa fa-bars" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(MainHeader);

import React, { Component } from 'react';
import { Button, Alert, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input, Col } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import "../../Pages/css/_authentication.css"
import Select from "react-select";

import "../Authorization/login.css"
import { withNamespaces } from 'react-i18next';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../Common/Loader';
import { BASEURL, getcountrycode } from '../../Global';

class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputFisrtName: "",
            inputLastName: "",
            inputEmail: "",
            inputMobile: "",
            selectedLanguage: "",
            inputDate: "",
            selectedGander: "",
            latitude: "",
            longitude: "",
            country_code: "",
            showLoader: false
        }
    }

    componentDidMount() {

    }

    getLoaction() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.handleSuccess,
                this.handleError
            );
        } else {
            toastr.error("Geolocation is not supported by your browser")
            this.setState({ error: 'Geolocation is not supported by your browser' });
        }
    }

    handleSuccess = async (position) => {
        if (position) {
            const con = await getcountrycode()
            localStorage.setItem("saralLongitude", position.coords.longitude)
            localStorage.setItem("saralLatitude", position.coords.latitude)
            localStorage.setItem("saralCountry", con.countrycode)
            this.setState({
                latitude: position.coords?.latitude,
                longitude: position.coords?.longitude,
                country_code: con.countrycode
            });
        }
    };

    handleError = () => {
        toastr.error("Please Allow Location ..")
    }

    async Register() {
        // console.log("Form data", JSON.stringify({
        //     "role_id_encode": "XbPW7awNkzl83LD6",
        //     "first_name": this.state.inputFisrtName,
        //     "last_name": this.state.inputLastName,
        //     "dob": new Date(`${this.state.inputDate}`).getFullYear() + "-" + (new Date(`${this.state.inputDate}`).getMonth() + 1) + "-" + new Date(`${this.state.inputDate}`).getDate(),
        //     "gender": this.state.selectedGander,
        //     "email": this.state.inputEmail,
        //     "mobile": this.state.inputMobile,
        //     "is_active": "Active",
        //     "gach": "1tithi",
        //     "language": this.state.selectedLanguage,
        //     // "location": this.state.inputLocation,
        //     "calendar_setting": "Gregorian",
        //     "latitude": this.state.latitude,
        //     "longitude": this.state.longitude,
        //     "country_code": this.state.country_code,
        //     // "country_code": "IN",
        // }))
        this.setState({ showLoader: true })
        try {
            await fetch(`${BASEURL}/createtenantuserswithoutauth`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "role_id_encode": "XbPW7awNkzl83LD6",
                        "first_name": this.state.inputFisrtName,
                        "last_name": this.state.inputLastName,
                        "dob": new Date(`${this.state.inputDate}`).getFullYear() + "-" + (new Date(`${this.state.inputDate}`).getMonth() + 1) + "-" + new Date(`${this.state.inputDate}`).getDate(),
                        "gender": this.state.selectedGander,
                        "email": this.state.inputEmail,
                        "mobile": this.state.inputMobile,
                        "is_active": "Active",
                        "gach": "1tithi",
                        "language": this.state.selectedLanguage,
                        // "location": this.state.inputLocation,
                        "calendar_setting": "Gregorian",
                        "latitude": this.state.latitude,
                        "longitude": this.state.longitude,
                        "country_code": this.state.country_code,
                        // "country_code": "IN",
                    })
                })
                .then((response) => {
                    if (response.status === 201) {
                        response.json().then(async (responseJson) => {
                            // console.log("Respionse JSON", responseJson);
                            // if (responseJson.data) {
                            if (responseJson.id) {
                                await toastr.success("Registration Succesfull !!")
                                this.setState({ showLoader: false })
                                window.location.href = process.env.PUBLIC_URL + '/login';
                            } else {
                                toastr.warning("Something Went Wrong !!")
                                this.setState({ showLoader: false })
                            }
                        });
                    } else {
                        response.json().then((responseJson) => {
                            toastr.error(responseJson.message)
                            this.setState({ showLoader: false })
                        })
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async validation() {
        if (this.state.inputFisrtName.trim().length < 1) {
            toastr.warning('Enter First Name');
        }
        else if (this.state.inputLastName.trim().length < 1) {
            toastr.warning('Enter Last Name');
        }
        else if (this.state.inputDate == "Date of Birth") {
            toastr.warning('Enter Date of Birth');
        }
        else if (this.state.selectedGander.trim().length < 1) {
            toastr.warning('Select Gender');
        }
        else if (this.state.inputEmail.trim().length < 1) {
            toastr.warning('Enter Valid Email id');
        }
        else if (this.state.inputMobile.trim().length < 10) {
            toastr.warning('Enter Valid Mobile Number');
        }
        else if (this.state.selectedLanguage.trim().length < 1) {
            toastr.warning('Select Language');
        }
        else if (await this.state.longitude.length < 1) {
            toastr.warning('Get your current Location');
        }
        else {
            await this.Register()
        }
    }


    render() {
        const optionLanguage = [
            { label: "Hindi", value: "Hindi" },
            { label: "English", value: "English" },
            { label: "Gujarati", value: "Gujarati" },
        ];
        const optionGander = [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
        ];

        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className='ast_toppadder70 ast_bottompadder20 custom-bg-login'>
                    <div className="ast_about_wrapper ast_toppadder70 ast_bottompadder70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="ast_heading">
                                        <h1>Welcome Back to <span>Saral Jain Panchang</span></h1>
                                        <p>Sign in to continue to Saral Jain Panchang.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div className="ast_about_info_img">
                                        <img src={require("../../Assets/images/about-img.png")} alt="About" />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                                    <div className='row'>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                                            {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null}
                                            <div className="">
                                                <AvForm className="form-horizontal"
                                                // onValidSubmit={this.tog_static} >
                                                >

                                                    <div className="mb-3">
                                                        <Label htmlFor="mobile">First Name</Label>
                                                        <AvField
                                                            name="FirstName"
                                                            value={this.state.inputFisrtName}
                                                            type="text"
                                                            onChange={(e) => this.setState({ inputFisrtName: e.target.value })}
                                                            className="form-control"
                                                            id="FirstName"
                                                            errorMessage="Please Enter Valid First name"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="mobile">Last Name</Label>
                                                        <AvField
                                                            name="LastName"
                                                            value={this.state.inputLastName}
                                                            onChange={(e) => this.setState({ inputLastName: e.target.value })}
                                                            type="text"
                                                            className="form-control"
                                                            id="LastName"
                                                            errorMessage="Please Enter Valid Last Name"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="mobile">Email</Label>
                                                        <AvField
                                                            name="Email"
                                                            value={this.state.inputEmail}
                                                            onChange={(e) => this.setState({ inputEmail: e.target.value })}
                                                            type="text"
                                                            className="form-control"
                                                            id="Email"
                                                            errorMessage="Please Enter Valid Email ID"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="mobile">Mobile</Label>
                                                        <AvField
                                                            name="mobile"
                                                            value={this.state.inputMobile}
                                                            onChange={(e) => this.setState({ inputMobile: e.target.value })}
                                                            type="text"
                                                            className="form-control"
                                                            id="mobile"
                                                            errorMessage="Please Enter Valid Mobile Number"
                                                            validate={{
                                                                required: { value: true },
                                                                minLength: { value: 10 },
                                                                maxLength: { value: 10 }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label"> Language</Label>
                                                        <Select
                                                            value={optionLanguage.find(option => option.value === this.state.selectedLanguage)}
                                                            onChange={(e) => this.setState({ selectedLanguage: e.value })}
                                                            options={optionLanguage}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="mobile">Date Of Birth</Label>
                                                        <div className="">
                                                            <Flatpickr
                                                                key={this.state.inputDate}
                                                                // disabled
                                                                // defaultValue={new Date(this.state.inputDate).toLocaleString()}
                                                                value={new Date(this.state.inputDate)}
                                                                onChange={(e) => this.setState({ inputDate: new Date(`${e}`) })}
                                                                className="form-control d-block"
                                                                placeholder="DD-MM-YYYY"
                                                                options={{
                                                                    dateFormat: "d-m-Y"
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Gander</Label>
                                                        <Select
                                                            value={optionGander.find(option => option.value === this.state.selectedGander)}
                                                            onChange={(e) => this.setState({ selectedGander: e.value })}
                                                            options={optionGander}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>

                                                    {this.state.latitude &&
                                                        <div className='card'>
                                                            <div style={{ marginHorizontal: 20, backgroundColor: "#fff", borderRadius: 10, padding: 10 }}>
                                                                <p className='m-0' style={{ color: "#000" }}>{`"Latitude :"${this.state.latitude}`}</p>
                                                                <p className='m-0' style={{ color: "#000" }}>{`"Longitude :"${this.state.longitude}`}</p>
                                                                <p className='m-0' style={{ color: "#000" }}>{`"Country :"${this.state.country_code}"`}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!this.state.latitude &&
                                                        <a onClick={() => this.getLoaction()} className="ast_btn mt-4 w-100">Get Your Current Location</a>
                                                        // <Button color="primary" className="w-md waves-effect waves-light" onClick={() => this.getLoaction()}>Get Your Current Location</Button>
                                                    }
                                                </AvForm>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <a onClick={() => this.validation()} className="ast_btn mt-4">Sign Up</a>
                                        {/* <Button color="primary" className="w-md waves-effect waves-light" onClick={() => this.validation()}>Sign Up</Button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Signup);
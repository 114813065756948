import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Slider from "../../Pages/Carousel/Slider";
import About from "../../Pages/Common/About";
import Services from "../../Pages/Common/Services";
import Testimonial from "../../Pages/Common/Testimonial";
import Download from "../../Pages/Common/Download";
import MainHeader from "../../Pages/Common/Mainheader";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {


        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                <Slider />
                <About />
                <Services />
                {/* <Testimonial /> */}
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Index;

import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Loginpage from "../../Pages/Authorization/Login";
import Download from "../../Pages/Common/Download";
import Breadcrumb from "../../Pages/Common/Breadcrumb";
import MainHeader from "../../Pages/Common/Mainheader";
import Signup from "../../Pages/Authorization/Signup";


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                <Breadcrumb title={"Sign Up"} />
                <Signup />
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Register;

import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

import translationENG from './Components/Languages/locales/en/translation.json';
import translationHIN from './Components/Languages/locales/hi/translation.json';
import translationGUJ from './Components/Languages/locales/gu/translation.json';

//translations
const resources = {
    eng: {
        translation: translationENG
    },
    hin: {
        translation: translationHIN
    },
    guj: {
        translation: translationGUJ
    }
};

i18n
    .use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        compatibilityJSON: 'v3',
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Download from "../../Pages/Common/Download";
import MainHeader from "../../Pages/Common/Mainheader";
import Breadcrumb from "../../Pages/Common/Breadcrumb";
import Services from "../../Pages/Common/Services";
import Hora from "../../Pages/Common/Services/Hora";


class HoraPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                <Breadcrumb title="Hora" />
                <Hora />
                <Services />
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default HoraPage;

import React, { Component } from "react";
import Flatpickr from "react-flatpickr"
import { Col } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import "../../../Components/css/navkarshi.css"
import getCurrentDate, { BASEURL } from "../../../Global.js";
import { withNamespaces } from "react-i18next";
import Loader from "../Loader";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

class Suryarashi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputDate: new Date(),
            suryarashiData: [],
            isLoading: false

        };
        this.interval = null;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getSuryarashi(this.state.inputDate)

        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
        this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds
    }
    async getSuryarashi(date) {
        // console.log("date", date, this.state.inputDate)
        this.setState({ showLoader: true, inputDate: date })
        const Token = await localStorage.getItem("authToken")

        const year = new Date(`${date}`).getFullYear();
        const month = new Date(`${date}`).getMonth() + 1;
        const day = new Date(`${date}`).getDate();
        const datstring = year + "-" + month + "-" + day

        // console.log("date ", date)
        // console.log("Form data", JSON.stringify({
        //     "custom_date": datstring,
        // }))

        try {
            await fetch(`${BASEURL}/getfrontsuryarashi`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "custom_date": datstring,
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            this.setState({ suryarashiData: responseJson.data, showLoader: false, inputDate: date })
                            // this.props.navigation.navigate("LoginOTP", { mobile: responseJson.data.mobile })
                        } else {
                            toastr.warning("Something Went Wrong !!")

                            this.setState({ showLoader: false, suryarashiData: null })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder40 bg-light">
                    <div className="container" style={{ maxHeight: 500 }}>
                        <div className="row ast_bottompadder30" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Col onClick={() => this.getSuryarashi(new Date(new Date(this.state.inputDate).setDate(this.state.inputDate.getDate() - 1)))} sm="auto" style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, backgroundColor: "#3b3483", color: "#fff" }}>
                                <div className="form-group my-2 arrowleft">
                                    <i className="fa fa-chevron-left" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                                </div>
                            </Col>
                            <Col sm="auto" style={{ backgroundColor: "#fff" }}>
                                <div className="form-group my-2">
                                    <div className="input-group">
                                        <Flatpickr
                                            key={this.state.inputDate}
                                            value={this.state.inputDate}
                                            onChange={(e) => {
                                                this.setState({ inputDate: new Date(`${e}`) })
                                                this.getSuryarashi(new Date(`${e}`))
                                            }}
                                            className="form-control d-block text-center"
                                            placeholder="dd M,yyyy"
                                            options={{
                                                dateFormat: "d-m-Y"
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm="auto" onClick={() => this.getSuryarashi(new Date(new Date(this.state.inputDate).setDate(this.state.inputDate.getDate() + 1)))} style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, backgroundColor: "#3b3483", color: "#fff" }}>
                                <div className="form-group my-2">
                                    <i className="fa fa-chevron-right" style={{ fontSize: 32, marginTop: 3.5 }}></i>
                                </div>
                            </Col>
                            <Col sm="auto" onClick={() => this.getSuryarashi(new Date())} style={{ borderRadius: 10, backgroundColor: "#3b3483", color: "#fff", marginLeft: 10 }}>
                                <div className="form-group" style={{ marginBlockStart: 10, marginBlockEnd: 12 }}>
                                    <span className="mx-2" style={{ fontSize: 18 }}>{this.props.t(`Today`)}</span>
                                </div>
                            </Col>
                        </div>
                        <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 15, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', }}>
                            <div className="row mx-2">
                                <Col className="text-center mb-3 p-3 fw-bold " style={{ backgroundColor: "#3b3483", color: "#fff" }}>{this.props.t(`Rashi Name`)}</Col>
                                <Col className="text-center mb-3 p-3 fw-bold col-1" style={{ backgroundColor: "#3b3483", color: "#fff" }}>|</Col>
                                <Col className="text-center mb-3 p-3 fw-bold " style={{ backgroundColor: "#3b3483", color: "#fff" }}>{this.props.t(`Time`)}</Col>
                            </div>
                            {this.state.suryarashiData?.length > 0 ?
                                <>
                                    {this.state.suryarashiData.map((item) => {
                                        let startdate = getCurrentDate(item.start_date?.split(" ")[0])
                                        let startday = this.props.t(startdate?.split("/")[0])
                                        let startmonth = this.props.t(startdate?.split("/")[1])
                                        let startyear = this.props.t(startdate?.split("/")[2])

                                        let enddate = getCurrentDate(item.end_date?.split(" ")[0])
                                        let endday = this.props.t(enddate?.split("/")[0])
                                        let endmonth = this.props.t(enddate?.split("/")[1])
                                        let endyear = this.props.t(enddate?.split("/")[2])

                                        let starttime = null
                                        let starthour = this.props.t(item.start_date?.split(" ")[1].split(":")[0])
                                        let startmin = this.props.t(item.start_date?.split(" ")[1].split(":")[1])
                                        starttime = starthour + " : " + startmin

                                        let endtime = null
                                        let endhour = this.props.t(item.end_date?.split(" ")[1].split(":")[0])
                                        let endmin = this.props.t(item.end_date?.split(" ")[1].split(":")[1])
                                        endtime = endhour + " : " + endmin


                                        return (
                                            <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2, alignItems: 'center' }} className="row mx-2 p-3">
                                                <Col className="text-center fw-bold ">
                                                    {this.props.t(item.rashi_name)}
                                                    {/* {this.state.selectedLanguage === "Gujarati" && item.rashi_name_gujarati}
                                                    {this.state.selectedLanguage === "Hindi" && item.rashi_name_hindi} */}
                                                </Col>
                                                <Col className="text-center fw-bold col-1">|</Col>
                                                <Col className="text-center fw-bold ">
                                                    {/* {this.state.selectedLanguage === "English" && day + "/" + month + "/" + year}
                                                    {this.state.selectedLanguage === "Gujarati" && day + "/" + month + "/" + year}
                                                    {this.state.selectedLanguage === "Hindi" && day + "/" + month + "/" + year} */}
                                                    {/* {starttime} - {endtime} */}
                                                    {/* <p className="m-0 p-0">{this.props.t("Start :")} {startday + "/" + startmonth + "/" + startyear} {starthour} : {startmin}</p>
                                                    <p className="m-0 p-0">{this.props.t("End :")}  {endday + "/" + endmonth + "/" + endyear} {endhour} : {endmin}</p> */}
                                                    {startday + "/" + startmonth + "/" + startyear} [{starthour} : {startmin}] <br /> {this.props.t('to')} <br /> {endday + "/" + endmonth + "/" + endyear} [{endhour} : {endmin}]
                                                </Col>
                                            </div>
                                        )
                                    })}
                                </> :
                                <div style={{ justifyContent: 'center', width: "100%", height: 150, textAlign: 'center', display: 'flex' }}>
                                    <h2 style={{ margin: 'auto' }}>{this.props.t(`No Data Found !`)}</h2>
                                </div>
                            }
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

export default withNamespaces()(Suryarashi);

import React, { Component } from "react";
import $ from "jquery";
import jQuery from "jquery";

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }
    render() {
        return (
            <React.Fragment>
                {this.props.isLoading &&
                    <div className="as_loader">
                        <div className="as_spinner">
                            <img style={{ width: 200 }} src={require('../../Assets/loader.png')} alt="" className="img-responsive" />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Loader;

import React, { Component } from "react";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import i18n from "../../i18n";
import { BASEURL } from "../../Global";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolledPast300px: false,
            themeSettingData: [],
            currentDate: new Date(),
        };
    }

    componentDidMount() {
        this.getThemeSetting()
        this.getuser()
        const isLogin = localStorage.getItem('authUserJain')
        if (isLogin === "true") {
            this.setState({ isLogin: true })
        } else {
            this.setState({ isLogin: false })
        }
    }

    async getuser() {
        this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")
        if (userID) {
            try {
                await fetch(`${BASEURL}/findtenantusersbyid/${userID}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        response.json().then(async (responseJson) => {
                            if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                                // this.props.history.push(process.env.PUBLIC_URL + '/login')
                                localStorage.clear()
                                window.location.href = process.env.PUBLIC_URL + "/login"
                                return false;
                            }
                            if (responseJson.data) {
                                // console.log("Respionse JSON", responseJson);

                                this.setState({
                                    userData: responseJson.data,
                                    selectedLanguage: responseJson.data.language,
                                    selectedCalendarType: responseJson.data.calendar_setting,
                                    showLoader: false
                                })
                                this.checkNewData(responseJson.data)
                                // console.log("console log data", responseJson.data)
                                await this.changeLanguageAction(responseJson.data.language)
                            } else {
                                await this.changeLanguageAction(responseJson.data?.language)
                                this.setState({ showLoader: false })
                            }
                        });

                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    async checkNewData(data) {
        // this.setState({ showLoader: true, selectedEnglishMonth: month, selectedEnglishYear: year })
        const Token = await localStorage.getItem("authToken")
        const storageTime = await localStorage.getItem("localDataDate")
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        try {
            await fetch(`${BASEURL}/getlatestdatafoundforlocalstorage`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "storage_date": storageTime,
                        "today_date": `${year}-${month}-${date}`
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.syncdata == "yes") {
                            console.log('syncdata', responseJson.syncdata)
                            this.getLocatData(this.state.currentDate.getFullYear(), data.latitude, data.longitude, data.country_code, data.calendar_setting, data.vikram_samvat)

                        } else {
                            // toastr.warning("Something Went Wrong !!")
                            this.setState({ showLoader: false, })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async getLocatData(year, latitude, longitude, code, type, vikramsamvat) {
        const Token = await localStorage.getItem("authToken")
        try {
            await fetch(`${BASEURL}/getfrontcalendarforyearoffline`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "year": year,
                        "type": type, //gregorian , hindu
                        "latitude": latitude,
                        "longitude": longitude,
                        "country_code": code,
                        "vikram_samvat": vikramsamvat
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        const today = new Date();
                        const month = today.getMonth() + 1;
                        const year = today.getFullYear();
                        const date = today.getDate();
                        localStorage.setItem("localData", JSON.stringify(responseJson))
                        localStorage.setItem("localDataDate", `${year}-${month}-${date}`)
                        // this.login(this.state.inputMobile)
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }

    async getThemeSetting() {
        this.setState({ isLoading: true })
        try {
            await fetch(`${BASEURL}/findthemesettingswithoutauth/NxOpZowo9GmjKqdR`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson) {
                            if (responseJson) {
                                this.setState({
                                    themeSettingData: responseJson.data,
                                    isLoading: false
                                })
                                // console.log("Theme Created", responseJson)
                            }
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async logOut() {
        localStorage.clear()
        toastr.success("Logout Successfull !!")
        window.location.href = process.env.PUBLIC_URL + "/"
    }

    render() {
        return (
            <React.Fragment>
                <div className="ast_top_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_contact_details">
                                    <ul>
                                        <li><i className="fa fa-phone" aria-hidden="true"></i> {this.state.themeSettingData?.mobile}</li>
                                        <li><a href={`mailto: ${this.state.themeSettingData?.email}`}><i className="fa fa-envelope-o" aria-hidden="true"></i> {this.state.themeSettingData?.email}</a></li>
                                    </ul>
                                </div>
                                <div className="ast_autho_wrapper">
                                    <ul>
                                        {this.state.isLogin &&
                                            <li><a className="popup-with-zoom-anim" href={process.env.PUBLIC_URL + "/calender-pdf"}><i className="fa fa-calendar" aria-hidden="true"></i> Calender PDF</a></li>}
                                        {this.state.isLogin &&
                                            <li><a className="popup-with-zoom-anim" href={process.env.PUBLIC_URL + "/user-notification"}><i className="fa fa-bell" aria-hidden="true"></i> User Notification</a></li>}
                                        {this.state.isLogin &&
                                            <li><a className="popup-with-zoom-anim" href={process.env.PUBLIC_URL + "/user-preference"}><i className="fa fa-gear" aria-hidden="true"></i> User Preference</a></li>}
                                        {this.state.isLogin ?
                                            <li><a className="popup-with-zoom-anim" href={process.env.PUBLIC_URL + "/user-profile"}><i className="fa fa-user" aria-hidden="true"></i> My Profile</a></li> :
                                            <li><a className="popup-with-zoom-anim" href={process.env.PUBLIC_URL + "/login"}><i className="fa fa-sign-in" aria-hidden="true"></i> Log In</a></li>
                                        }
                                        {this.state.isLogin ?
                                            <li><a onClick={() => this.logOut()} className="popup-with-zoom-anim" href="#"><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</a></li> :
                                            <li><a className="popup-with-zoom-anim" href={process.env.PUBLIC_URL + "/signup"}><i className="fa fa-user-plus" aria-hidden="true"></i> Sign Up</a></li>}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Header;

import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Loader from "./Loader";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 500);
    }

    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isLoading} />
                <div className="ast_about_wrapper ast_toppadder70 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_heading">
                                    <h1>about <span>Saral Jain Panchang</span></h1>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by Lorem Ipsum.</p> */}
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                <div className="ast_about_info_img">
                                    <img src={require("../../Assets/images/about-img.png")} alt="About" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                                <div className="ast_about_info">
                                    {/* <h4>know about saral jain panchang</h4> */}
                                    <p>Welcome to Saral Jain Panchang, your trusted companion in navigating the auspicious and significant events according to the Jain calendar. Rooted in the rich cultural and religious heritage of Jainism, our app is designed to provide you with accurate and comprehensive information about the Jain Panchang.
                                        At Saral Jain Panchang, we are committed to preserving and promoting Jain culture through modern technology. As the name suggests, Saral Jain Panchang strives to provide a simple, intuitive, and ad-free experience for users of all ages.</p>
                                    {/* <a href="#" className="ast_btn">know more</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(About);

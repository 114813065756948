import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container, Form, Input, } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"

//Import Breadcrumb
import { ThreeDots } from "react-loader-spinner";
import { AvField, AvForm } from "availity-reactstrap-validation";

import $ from "jquery";
import "../../../node_modules/dropify/dist/js/dropify.js"
import "../../../node_modules/dropify/dist/css/dropify.css"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from "./Loader.js";
import { BASEURL } from "../../Global.js";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFirstName: "",
            inputMiddleName: "",
            inputLastName: "",
            inputDate: "",
            inputGender: "",
            inputAddress: "",
            inputCity: "",
            inputState: "",
            inputZipcode: "",
            inputCountry: "",
            latitude: "",
            longitude: "",
            country_code: "",
            profileImage: "",

            isChnage: false,
            showLoader: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }

    componentDidMount() {
        $(document).ready(function () {
            setTimeout(() => {
                $('.dropify').dropify();
            }, 1500);
        });

        this.getuser()
    }

    async getuser() {
        this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/findtenantusersbyid/${userID}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            // console.log("Respionse JSON", responseJson);

                            this.setState({
                                userData: responseJson.data,
                                inputDate: responseJson.data.dob,
                                profileImage: responseJson.data.profile_image,
                                inputFirstName: responseJson.data.first_name,
                                inputMiddleName: responseJson.data.middle_name,
                                inputLastName: responseJson.data.last_name,
                                inputGender: responseJson.data.gender,
                                inputAddress: responseJson.data.address,
                                inputCity: responseJson.data.city,
                                inputState: responseJson.data.state,
                                inputZipcode: responseJson.data.zipcode,
                                inputCountry: responseJson.data.country,
                                selectedLanguage: responseJson.data.language,
                                latitude: responseJson.data.latitude,
                                longitude: responseJson.data.longitude,
                                country_code: responseJson.data.country_code,
                                showLoader: false
                            })
                        } else {
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async handleSubmit(event, values) {
        this.setState({ showLoader: true })
        // console.log("Form Data", JSON.stringify({
        //     "first_name": this.state.inputFirstName,
        //     "middle_name": this.state.inputMiddleName,
        //     "last_name": this.state.inputLastName,
        //     "profile_image_encode": this.state.isChnage && this.state.profileImage,
        //     "dob": new Date(`${this.state.inputDate}`).getFullYear() + "-" + (new Date(`${this.state.inputDate}`).getMonth() + 1) + "-" + new Date(`${this.state.inputDate}`).getDate(),
        //     "gender": this.state.inputGender,
        //     "email": this.state.userData.email,
        //     "mobile": this.state.userData.mobile,
        //     "address": this.state.inputAddress,
        //     "country": this.state.inputCountry,
        //     "state": this.state.inputState,
        //     "city": this.state.inputCity,
        //     "zipcode": this.state.inputZipcode,
        //     "language": this.state.selectedLanguage,
        //     "latitude": this.state.userData.latitude,
        //     "longitude": this.state.userData.longitude,
        //     "is_active": this.state.userData.is_active,
        // }))
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")
        try {
            await fetch(`${BASEURL}/updatetenantusers/${userID}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "first_name": this.state.inputFirstName,
                        "middle_name": this.state.inputMiddleName,
                        "last_name": this.state.inputLastName,
                        "profile_image_encode": this.state.isChnage && this.state.profileImage,
                        "dob": new Date(`${this.state.inputDate}`).getFullYear() + "-" + (new Date(`${this.state.inputDate}`).getMonth() + 1) + "-" + new Date(`${this.state.inputDate}`).getDate(),
                        "gender": this.state.inputGender,
                        "calendar_setting": this.state.userData.selectedCalendarType,
                        "email": this.state.userData.email,
                        "mobile": this.state.userData.mobile,
                        "address": this.state.inputAddress,
                        "country": this.state.inputCountry,
                        "state": this.state.inputState,
                        "city": this.state.inputCity,
                        "zipcode": this.state.inputZipcode,
                        "language": this.state.selectedLanguage,
                        "latitude": this.state.userData.latitude,
                        "longitude": this.state.userData.longitude,
                        "is_active": this.state.userData.is_active,
                    })
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson.data) {
                                this.getuser()
                                toastr.success("Data Updated Successfully !!")
                                this.setState({ showLoader: false })
                                // console.log("Theme UPDATED", responseJson)
                            } else {
                                this.setState({ showLoader: false })
                                toastr.error("Something Went Wrong !!")
                            }
                        });
                    } else {
                        this.setState({ showLoader: false })
                        toastr.error("Something Went Wrong !!")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    handleAcceptedFiles = async (files) => {
        const filePromises = Array.from(files).map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve({
                        preview: URL.createObjectURL(file),
                        formattedSize: this.formatBytes(file.size),
                        file: file,
                        base64: e.target.result.split(',')[1], // Extract Base64 data
                    });
                };
                reader.readAsDataURL(file);
            });
        });
        const filesWithBase64 = await Promise.all(filePromises);
        this.setState({ profileImage: filesWithBase64[0].base64, isChnage: true });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {

        const optionLanguage = [
            { label: "Hindi", value: "Hindi" },
            { label: "English", value: "English" },
            { label: "Gujarati", value: "Gujarati" },
        ];

        const optionGander = [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
        ];

        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className="ast_about_wrapper ast_toppadder70 ast_bottompadder70">
                    <div className="container">
                        {this.state.isLoading ? (
                            <>
                                <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#3b3483"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                        justifyContent: "center",
                                    }}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </>
                        ) : (
                            <>
                                <div>
                                    <AvForm className="needs-validation" onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col lg="3">
                                                <Form>
                                                    <Label className="form-label" htmlFor="validationCustom04">Profile Image</Label>
                                                    <>
                                                        <input key={this.state.profileImage} type="file" className="dropify" data-height="250" data-max-width="50%" onChange={(e) => this.handleAcceptedFiles(e.target.files)} data-allowed-file-extensions="jpg png jpeg" data-default-file={this.state.profileImage ? this.state.profileImage : require('../../Assets/logo.jpg')} />
                                                        <script>
                                                            $(document).ready({
                                                                setTimeout(() => {
                                                                    $(".dropify").dropify()
                                                                }, 1500)
                                                            }),
                                                        </script>
                                                    </>
                                                </Form>
                                            </Col>
                                            <Col lg="9">
                                                <Row>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">First Name</Label>
                                                            <AvField
                                                                value={this.state.inputFirstName}
                                                                name="FirstName"
                                                                placeholder="First Name"
                                                                type="text"
                                                                errorMessage=" Please provide a valid First Name."
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputFirstName: e.target.value })}
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">Middle Name</Label>
                                                            <AvField
                                                                value={this.state.inputMiddleName}
                                                                name="MiddleName"
                                                                placeholder="Middle Name"
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputMiddleName: e.target.value })}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">Last Name</Label>
                                                            <AvField
                                                                value={this.state.inputLastName}
                                                                name="LastName"
                                                                placeholder="Last Name"
                                                                type="text"
                                                                errorMessage=" Please provide a valid Last Name."
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputLastName: e.target.value })}
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    {/* <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">Gander</Label>
                                                            <div className="form-check form-check-right mt-2" style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                                                                <div>
                                                                    <Input onChange={(e) => this.setState({ inputGender: e.target.value })} value="Male" checked={this.state.inputGender === "Male"} type="radio" id="customRadio1" name="customRadio" className="form-check-input" />
                                                                    <Label className="form-check-label" htmlFor="customRadio1">Male</Label>
                                                                </div>
                                                                <div>
                                                                    <Input onChange={(e) => this.setState({ inputGender: e.target.value })} value="Female" checked={this.state.inputGender === "Female"} type="radio" id="customRadio2" name="customRadio" className="form-check-input" defaultChecked />
                                                                    <Label className="form-check-label" htmlFor="customRadio2">Female</Label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Col> */}
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label">Gander</Label>
                                                            <Select
                                                                value={optionGander.find(option => option.value === this.state.inputGender)}
                                                                onChange={(e) => this.setState({ inputGender: e.value })}
                                                                options={optionGander}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="form-group mb-2">
                                                            <label>Date Of Birth</label>
                                                            <div className="">
                                                                <Flatpickr
                                                                    key={this.state.inputDate}
                                                                    // disabled
                                                                    // defaultValue={new Date(this.state.inputDate).toLocaleString()}
                                                                    value={new Date(this.state.inputDate)}
                                                                    onChange={(e) => this.setState({ inputDate: new Date(`${e}`) })}
                                                                    className="form-control d-block"
                                                                    placeholder="DD-MM-YYYY"
                                                                    options={{
                                                                        dateFormat: "d-m-Y"
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">Address</Label>
                                                            <AvField
                                                                value={this.state.inputAddress}
                                                                name="Address"
                                                                placeholder="Address"
                                                                type="text"
                                                                errorMessage=" Please provide a valid Address."
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputAddress: e.target.value })}
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">City</Label>
                                                            <AvField
                                                                value={this.state.inputCity}
                                                                name="City"
                                                                placeholder="City"
                                                                type="text"
                                                                errorMessage=" Please provide a valid City."
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputCity: e.target.value })}
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">State</Label>
                                                            <AvField
                                                                value={this.state.inputState}
                                                                name="State"
                                                                placeholder="State"
                                                                type="text"
                                                                errorMessage=" Please provide a valid State."
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputState: e.target.value })}
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="validationCustom03">Zip Code</Label>
                                                            <AvField
                                                                value={this.state.inputZipcode}
                                                                name="ZipCode"
                                                                placeholder="Zip Code"
                                                                type="text"
                                                                errorMessage=" Please provide a valid Zip Code."
                                                                className="form-control"
                                                                onChange={(e) => this.setState({ inputZipcode: e.target.value })}
                                                                validate={{ required: { value: true } }}
                                                                id="validationCustom03"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg="5">
                                                        <div className="mb-3">
                                                            <Label className="form-label"> Language</Label>
                                                            <Select
                                                                value={optionLanguage.find(option => option.value === this.state.selectedLanguage)}
                                                                onChange={(e) => this.setState({ selectedLanguage: e.value })}
                                                                options={optionLanguage}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg="4">
                                                        <div className="mb-3" style={{ display: 'grid' }}>
                                                            <Label className="form-label"> Latitude : {this.state.latitude}</Label>
                                                            <Label className="form-label"> Longitude : {this.state.longitude}</Label>
                                                            <Label className="form-label"> Country Code : {this.state.country_code}</Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Button className="mt-3 ast_btn " style={{ float: 'right', width: "25%" }} color="primary" type="submit">Submit</Button>
                                    </AvForm>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default UserProfile;

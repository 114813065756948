import React, { Component } from "react";
import { BASEURL } from "../../Global";

class GetinTouch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            themeSettingData: [],
        };
    }

    componentDidMount() {
        this.getThemeSetting()
    }

    async getThemeSetting() {
        this.setState({ isLoading: true })
        try {
            await fetch(`${BASEURL}/findthemesettingswithoutauth/NxOpZowo9GmjKqdR`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson) {
                            if (responseJson) {
                                if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                                    // this.props.history.push(process.env.PUBLIC_URL + '/login')
                                    localStorage.clear()
                                    window.location.href = process.env.PUBLIC_URL + "/login"
                                    return false;
                                }
                                this.setState({
                                    themeSettingData: responseJson.data,
                                    isLoading: false
                                })
                                // console.log("Theme Created", responseJson)
                            }
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="ast_contact_wrapper ast_toppadder70 ast_bottompadder50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_heading">
                                    <h1>get in <span>touch</span></h1>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected hummer.</p> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="ast_contact_info">
                                    <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                                    <h4>phone</h4>
                                    <p>{this.state.themeSettingData?.mobile}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="ast_contact_info">
                                    <span><i className="fa fa-envelope-open-o" aria-hidden="true"></i></span>
                                    <h4>email</h4>
                                    <p><a href={`mailto: ${this.state.themeSettingData?.email}`}>{this.state.themeSettingData?.email}</a></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="ast_contact_info">
                                    <span><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                    <h4>address</h4>
                                    <p>{this.state.themeSettingData?.address}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GetinTouch;

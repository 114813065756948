import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { BASEURL } from "../../Global";

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            themeSettingData: []
        };
    }

    componentDidMount() {
        this.getThemeSetting()
        const isLogin = localStorage.getItem('authUserJain')
        if (isLogin === "true") {
            this.setState({ isLogin: true })
        } else {
            this.setState({ isLogin: false })
        }
    }

    async getThemeSetting() {
        this.setState({ isLoading: true })
        try {
            await fetch(`${BASEURL}/findthemesettingswithoutauth/NxOpZowo9GmjKqdR`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson) {
                            if (responseJson) {
                                if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                                    // this.props.history.push(process.env.PUBLIC_URL + '/login')
                                    localStorage.clear()
                                    window.location.href = process.env.PUBLIC_URL + "/login"
                                    return false;
                                }
                                this.setState({
                                    themeSettingData: responseJson.data,
                                    isLoading: false
                                })
                                // console.log("Theme Created", responseJson)
                            }
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="ast_download_wrapper ast_toppadder70 ast_bottompadder70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_heading">
                                    <h1>Download our <span>Mobile App</span></h1>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by Lorem Ipsum.</p> */}
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_download_box">
                                    <ul>
                                        <li><a href={this.state.themeSettingData?.ios_play_store_link}><img src={require('../../Assets/images/content/download1.png')} alt="Download" title="Download" /></a></li>
                                        <li><a href={this.state.themeSettingData?.google_play_store_link}><img src={require('../../Assets/images/content/download2.png')} alt="Download" title="Download" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Download);

import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Download from "../../Pages/Common/Download";
import MainHeader from "../../Pages/Common/Mainheader";
import Breadcrumb from "../../Pages/Common/Breadcrumb";
import Choghadiya from "../../Pages/Common/Services/Choghadiya";
import Services from "../../Pages/Common/Services";


class ChoghadiyaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                <Breadcrumb title="Choghadiya" />
                <Choghadiya />
                <Services />
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default ChoghadiyaPage;

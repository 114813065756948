import React, { Component } from 'react';
import { Button, Alert, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import OtpInput from 'react-otp-input';
import "../../Pages/css/_authentication.css"
import "../Authorization/login.css"
import { withNamespaces } from 'react-i18next';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Loader from '../Common/Loader';
import { BASEURL } from '../../Global';


class Loginpage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            inputMobile: "",
            inputOTP: "",
            modal_static: false,
            disableResend: true,
            timer: 30,
            formData: new FormData(),
            showLoader: false
        }

        this.tog_static = this.tog_static.bind(this);
        this.verifyOTP = this.verifyOTP.bind(this);
        this.sendOTP = this.sendOTP.bind(this);
        this.login = this.login.bind(this);
    }

    async verifyOTP() {
        if (this.state.inputOTP.length < 6) {
            toastr.warning("Enter Valid OTP")
        } else {
            this.setState({ showLoader: true })
            // console.log("Form data", JSON.stringify({
            //     mobile: this.state.inputMobile,
            //     otp: this.state.inputOTP
            // }))
            try {
                await fetch(`${BASEURL}/verifyotp`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            mobile: this.state.inputMobile,
                            otp: this.state.inputOTP
                        })
                    })
                    .then((response) => {
                        response.json().then((responseJson) => {
                            if (responseJson.result === true) {
                                this.setState({ modal_static: false })
                                this.setState({ showLoader: false })
                                toastr.success("Login Successfull !!")
                                localStorage.setItem("authUserJain", this.state.authUserJain)
                                localStorage.setItem("authToken", this.state.authToken)
                                localStorage.setItem("authUser_ID", this.state.authUser_ID)
                                window.location.href = process.env.PUBLIC_URL + '/user-dashboard';
                                // this.login(this.state.inputMobile)
                            }
                            if (responseJson.result === false) {
                                toastr.warning("Enter valid OTP !!")
                                this.setState({ showLoader: false })
                            }
                        });

                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }

    async sendOTP(mobile) {
        this.setState({ inputOTP: '' });
        this.setState({ showLoader: true })
        // console.log("Form data", JSON.stringify({
        //     mobile: mobile
        // }))
        try {
            await fetch(`${BASEURL}/resendotp`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        mobile: mobile
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson.data) {
                            this.CounterInterval()
                            this.setState({ showLoader: false })
                            toastr.success("OTP Sent Succesfully !!")
                        }
                        else {
                            // console.log("Respose Not Done")
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async login(event, values) {
        const { formData } = this.state;
        formData.append("mobile", values.mobile);
        this.setState({ showLoader: true })

        try {
            // console.log("For Dtaaa", formData)
            await fetch(`${BASEURL}/tenantuser/login`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    // "Content-Type": "multipart/form-data",
                },
                body: formData,
            })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then(async (responseJson) => {
                            // console.log("Login Response", responseJson);
                            if (responseJson.access_token) {
                                this.setState({
                                    authUserJain: "true",
                                    authToken: responseJson.access_token,
                                    authUser_ID: responseJson.user_id
                                })
                                await this.getuser(responseJson.access_token, responseJson.user_id)
                                this.setState({ inputMobile: values.mobile })
                                this.setState({ showLoader: false })
                                this.CounterInterval()
                                this.tog_static()
                            }
                        });
                    } else {
                        response.json().then((responseJson) => {
                            toastr.error(responseJson.message)
                            // console.log("inside Else")
                            this.setState({ showLoader: false })
                        })
                    }
                })
                .catch((error) => {
                    console.log("Request Error", error);
                });
        } catch (error) {
            console.log("Fetch Error", error);

        }
    }
    async getuser(token, id) {
        // this.setState({ isLoading: true })
        try {
            await fetch(`${BASEURL}/findtenantusersbyid/${id}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            // this.setState({
                            //     userData: responseJson.data
                            //     , selectedLanguage: responseJson.data.language
                            //     , latitude: responseJson.data.latitude
                            //     , longitude: responseJson.data.longitude
                            //     , country_code: responseJson.data.country_code
                            //     , selectedCalendarType: responseJson.data.calendar_setting
                            //     , vikram_samvat: responseJson.data.vikram_samvat
                            //     , month_in_gujarati: responseJson.data.month_in_gujarati
                            //     , isLoading: false
                            // })
                            // changeLanguageAction(responseJson.data.language)
                            this.getLocatData(this.state.currentDate.getFullYear(), responseJson.data.latitude, responseJson.data.longitude, responseJson.data.country_code, responseJson.data.calendar_setting, responseJson.data.vikram_samvat, token)
                        } else {
                            // this.setState({ isLoading: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async getLocatData(year, latitude, longitude, code, type, vikramsamvat, token) {
        try {
            await fetch(`${BASEURL}/getfrontcalendarforyearoffline`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "year": year,
                        "type": type, //gregorian , hindu
                        "latitude": latitude,
                        "longitude": longitude,
                        "country_code": code,
                        "vikram_samvat": vikramsamvat
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        const today = new Date();
                        const month = today.getMonth() + 1;
                        const year = today.getFullYear();
                        const date = today.getDate();
                        localStorage.setItem("localData", JSON.stringify(responseJson))
                        localStorage.setItem("localDataDate", `${year}-${month}-${date}`)
                        // this.login(this.state.inputMobile)
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async CounterInterval() {
        this.setState({
            disableResend: true,
            timer: 30,
        });
        this.interval = setInterval(
            () =>
                this.setState(
                    {
                        timer: this.state.timer - 1,
                    },
                    () => {
                        if (this.state.timer === 0) {
                            clearInterval(this.interval);
                            this.setState({ disableResend: false });
                        }
                    },
                ),
            2000,
        );
    }

    tog_static() {
        this.setState(prevState => ({
            modal_static: !prevState.modal_static
        }));
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.CounterInterval()
    }
    render() {

        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className='ast_toppadder70 ast_bottompadder20 custom-bg-login'>
                    <Modal
                        isOpen={this.state.modal_static}
                        // isOpen={!this.state.modal_static}
                        toggle={this.tog_static}
                        backdrop="static"
                    >
                        <ModalHeader
                        // toggle={() => this.setState({ modal_static: false })}
                        >
                            OTP Verification !!
                        </ModalHeader>
                        <ModalBody>
                            <OtpInput
                                containerStyle={{ justifyContent: 'center', marginBottom: 10 }}
                                inputStyle={{
                                    fontSize: 20,
                                    padding: 5,
                                    width: "15%",
                                    height: "auto",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                                }}
                                value={this.state.inputOTP}
                                onChange={(value) => this.setState({ inputOTP: value.replace(/\D/g, '') })}
                                numInputs={6}
                                renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                            />
                            <div className='resendotp'>
                                <span style={{ color: '#000', marginTop: 10 }}>
                                    Didn't get the code ?{'  '}
                                </span>
                                {this.state.disableResend ?
                                    <span className="textDisabled"> Resend {this.state.disableResend && (
                                        <span style={{ color: '#000' }}>
                                            {' ('} {this.state.timer} {') '}
                                        </span>
                                    )}</span> :
                                    <span
                                        onClick={() => {
                                            if (!this.state.disableResend) {
                                                // this.sendOTP()
                                                this.sendOTP(this.state.inputMobile)
                                                // console.log("Resend OTP")
                                            } else {
                                                // console.log("You can resend the code in 30 seconds")
                                            }
                                        }} style={{ cursor: 'pointer', color: "#0184c1", fontWeight: 'bold' }}>
                                        Resend
                                    </span>
                                }
                            </div>
                            <ModalFooter>
                                <Button type="button" color="light" onClick={() => this.setState({ modal_static: false })}>Close</Button>
                                <Button type="button" color="primary" onClick={() => this.verifyOTP()}>Submit</Button>
                            </ModalFooter>
                        </ModalBody>
                    </Modal>

                    <div className="ast_about_wrapper ast_toppadder70 ast_bottompadder70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="ast_heading">
                                        <h1>Welcome Back to <span>Saral Jain Panchang</span></h1>
                                        <p>Sign in to continue to Saral Jain Panchang.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div className="ast_about_info_img">
                                        <img src={require("../../Assets/images/about-img.png")} alt="About" />
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div>
                                        {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null}

                                        <div className="">
                                            <p>Enter Your Mobile Number</p>
                                            <AvForm className="form-horizontal"
                                                // onValidSubmit={this.tog_static} >
                                                onValidSubmit={this.login} >

                                                <div className="auth-form-group-custom mb-4">
                                                    <i className="fa fa-mobile auti-custom-input-icon"></i>
                                                    <Label htmlFor="mobile">Mobile</Label>
                                                    <AvField
                                                        name="mobile"
                                                        value={this.state.inputMobile}
                                                        type="text"
                                                        className="form-control"
                                                        id="mobile"
                                                        errorMessage="Please Enter Valid Mobile Number"
                                                        validate={{
                                                            required: { value: true },
                                                            minLength: { value: 10 },
                                                            maxLength: { value: 10 }
                                                        }}
                                                        placeholder="(999) 999 9999" />
                                                </div>
                                                <div className="mt-4">
                                                    {/* <a onClick={() => this.validation()} className="ast_btn mt-4">Sign Up</a> */}
                                                    <Button color="primary" className="w-md waves-effect waves-light ast_btn" type="submit">Log In</Button>
                                                </div>
                                            </AvForm>
                                        </div>

                                        <p className='mt-3'>Not a user ? Please <a className='testclass' href={process.env.PUBLIC_URL + "/signup"}><u>Click here</u></a> to register</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Loginpage);
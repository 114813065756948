import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import "../../Pages/Calender/calendar.css"
import Loader from "../Common/Loader";
import i18n from "../../i18n";
import Select from "react-select";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { BASEURL, getcountrycode } from "../../Global";
import ChartSection from "../CircularChart/ChartSection";
import GenerateCalendarPDF from "../Pdf/GenerateCalendarPDF";
import ProgressBar from "../CircularChart/ProgressBar";
const date = new Date();
const options = { month: 'long' };
// const monthName = date.toLocaleString('en-US', options);
const monthName = date.getMonth() + 1;
const yearName = date.getFullYear();
const dateName = date.getDate();
class Calender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            selectedEnglishMonth: monthName,
            selectedEnglishYear: yearName,
            selectedHinduYear: '',
            yearData: [],
            yearHindiData: [],
            userData: [],
            data: [],
            data: [],
            selectedLanguage: "",
            latitude: "",
            longitude: "",
            country_code: "",
            selectedCalendarType: "",
            vikram_samvat: "",
            selectedMonthGuj: "",
            isLoading: false,
            selectedMonth: "",
            selectedYear: "",
            selectedDateData: [],
            selectedDateDataModal: false,
            isDateSelected: false,
            IsPanchkalyan: false,
            IsJainfestival: false,
            IsExtraNotes: false,
            isOpen: "",
            getTithe: ""
        };
        this.interval = null;
    }

    async componentDidMount() {
        this.getuser()
        this.getYear()
        this.getHinduYear()
        const calender = JSON.parse(localStorage.getItem('localData'))
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            // console.log("LanguageLanguage", Language,)
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
        this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds

    }

    async getuser() {
        this.setState({ isLoading: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/findtenantusersbyid/${userID}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            this.setState({
                                userData: responseJson.data
                                , selectedLanguage: responseJson.data.language
                                , latitude: responseJson.data.latitude
                                , longitude: responseJson.data.longitude
                                , country_code: responseJson.data.country_code
                                , selectedCalendarType: responseJson.data.calendar_setting
                                , vikram_samvat: responseJson.data.vikram_samvat
                                , selectedHinduYear: responseJson.data.vikram_samvat
                                , month_in_gujarati: responseJson.data.month_in_gujarati
                                , getTithe: responseJson.data.tithi
                                , isLoading: false
                            })
                            // changeLanguageAction(responseJson.data.language)

                            localStorage.setItem("saralLongitude", responseJson.data.longitude)
                            localStorage.setItem("saralLatitude", responseJson.data.latitude)
                            localStorage.setItem("saralCountry", responseJson.data.country_code)

                            this.getCalender(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, responseJson.data.month_in_gujarati, responseJson.data.calendar_setting, responseJson.data.vikram_samvat)
                        } else {
                            this.setState({ isLoading: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async getCalender(year, month, monthguj, type, vikramsamvat) {
        // console.log("month of year", month)
        // this.setState({ isLoading: true })

        const Token = await localStorage.getItem("authToken")
        const Language = await localStorage.getItem('selectedLanguage')
        const longitude = localStorage.getItem("saralLongitude")
        const latitude = localStorage.getItem("saralLatitude")
        const Country = await localStorage.getItem("saralCountry")

        if (Language) {
            this.setState({ selectedLanguage: Language })
        }

        // console.log("Form data Calendar", JSON.stringify({
        //     "flag": "multiple",
        //     "year": year.toString(),
        //     "month": month.toString(),
        //     "type": type,
        //     "latitude": this.state.latitude ? this.state.latitude : longitude,
        //     "longitude": this.state.longitude ? this.state.longitude : latitude,
        //     "country_code": this.state.country_code ? this.state.country_code : Country,
        //      "vikram_samvat": vikramsamvat.toString(),
        //     "vikram_samvat222" : this.state.vikram_samvat == this.state.selectedHinduYear
        // }))
        // console.log('this.state.vikram_samvat == vikramsamvat', this.state.vikram_samvat ,this.state.selectedHinduYear)
        const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
        const data = this.state.selectedCalendarType == "Hindu" ? 
        year == yearName && vikramsamvat == this.state.selectedHinduYear && panchkalyanakLocale :
        year == yearName && panchkalyanakLocale
        if (data) {
            const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
            const filteredData = panchkalyanakLocale?.data.filter(item => {
                return parseInt(item.month, 10) == month;
            });
            this.setState({ data: filteredData })
            this.setState({ selectedMonth: parseInt(filteredData[0].month) })
            this.setState({ selectedMonthGuj: parseInt(filteredData.find((data) => data.day == dateName)?.month_in_gujarati) })
            //  this.setState({ selectedMonthGuj: 10 })
            this.setState({ selectedYear: year })
            this.setState({ isDateSelected: false })
        } else {
            this.setState({ isLoading: true })
            try {
                await fetch(`${BASEURL}/getfrontcalendar`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "flag": "multiple",
                            "year": year.toString(),
                            "month": month.toString(),
                            "month_in_gujarati": monthguj.toString(),
                            "type": type,
                            "latitude": this.state.latitude ? this.state.latitude : longitude,
                            "longitude": this.state.longitude ? this.state.longitude : latitude,
                            "country_code": this.state.country_code ? this.state.country_code : Country,
                            "vikram_samvat": vikramsamvat.toString()
                        }
                        )
                    })
                    .then((response) => {
                        if (response) {
                            response.json().then((responseJson) => {
                                // console.log("Dashboards calendar", responseJson)
                                if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                                    localStorage.clear()
                                    window.location.href = process.env.PUBLIC_URL + '/login';
                                    // navigation.navigate("Splash")
                                }
                                if (responseJson.data.length > 0) {
                                    this.setState({ data: responseJson.data })
                                    // this.setState({ selectedMonth: this.state.selectedCalendarType == "Hindu" ? parseInt(responseJson.month_gujarati) : parseInt(responseJson.month_english) })
                                    this.setState({ selectedMonth: parseInt(responseJson.month_english) })
                                    this.setState({ selectedMonthGuj: parseInt(responseJson.month_gujarati) })
                                    this.setState({ vikram_samvat: parseInt(responseJson.vikram_samvat) })
                                    // this.setState({ selectedHinduYear: parseInt(responseJson.vikram_samvat) })
                                    this.setState({ selectedYear: year })
                                    this.setState({ isDateSelected: false })
                                    this.setState({ isLoading: false })
                                } else {
                                    toastr.info("Calendar Ended We Will Update Data Soon !!")
                                    this.getuser()
                                    // this.getCalender(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.vikram_samvat)
                                    this.setState({ isLoading: false })
                                }
                            });
                        } else {
                            toastr.warning("Something Went Wrong !!")
                            this.setState({ isLoading: false })
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error !!', error);
            }
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }

    closeModal() {
        this.setState({ selectedDateDataModal: false })
    };
    isSelectedCloseModal() {
        this.setState({ isDateSelected: false })
    };

    toggle = (id) => {
        if (this.state.isOpen === id) {
            this.setState({ isOpen: "" })
        } else {
            this.setState({ isOpen: id })
        }
    };

    async getYear() {
        // this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/getfrontcalendargroupbyyear`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            const years = responseJson?.data?.map((item) => {
                                return { label: this.props.t(item?.year), value: item?.year }
                            }
                            )
                            // console.log("Respionse JSON", years);
                            this.setState({ yearData: years })
                            // console.log("console log data", this.state.userData)
                        } else {
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async getHinduYear() {
        // this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/getfrontcalendargroupbyhinduyear`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            const years = responseJson?.data?.map((item) => {
                                return { label: this.props.t(item?.year), value: item?.year }
                            }
                            )
                            // console.log("Respionse JSON", years);
                            this.setState({ yearHindiData: years })
                            // console.log("console log data", this.state.userData)
                        } else {
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    render() {

          const Latitude =  localStorage.getItem("saralLatitude")
        const Longitude =  localStorage.getItem("saralLongitude")
        const CountryCode =  localStorage.getItem("saralCountry")
        const language =  localStorage.getItem("selectedLanguage")
        const firstDayOfWeek = this.state.data?.length > 0 ? this.state.data[0].day_name : 'Sunday';
        const lastDayOfWeek = this.state.data?.length > 0 ? this.state.data[this.state.data?.length - 1].day_name : 'Sunday';

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const emptyColumnsStart = daysOfWeek.indexOf(firstDayOfWeek);
        // const emptyColumnsEnd = daysOfWeek.indexOf(lastDayOfWeek) == 6 ? 0 : (7 - (this.state.data.length % 7)) % 7;
        const emptyColumnsEnd = Number((6 - daysOfWeek.indexOf(lastDayOfWeek)).toFixed(0));

        const emptyDataStart = Array(emptyColumnsStart).fill({});
        const emptyDataEnd = Array(emptyColumnsEnd).fill({});

        const fullData = [...emptyDataStart, ...this.state.data, ...emptyDataEnd];

        const today = new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString();
        const month = (new Date().getMonth() + 1).toString().length === 1 ? '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString()
        const year = new Date().getFullYear().toString()
        // const today = '20';

        const CalendarItem = ({ date, isEmpty, index, props }) => {
            return (
                <div key={index} onClick={async () => {
                    if (date.day?.toString() && date.month) {
                        this.setState({ selectedDateData: date })
                        this.setState({ selectedDateDataModal: true, isOpen: "1" })
                    }
                   
                }
                } className={
                    `calendarItem col-1
                        ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : "border"}
                        ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : date.englsih_tithi_name === "Atham" ? "AthamItem" : "border"}
                        ${this.state.getTithe == "12" ? (date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : date.englsih_tithi_name === "Beej" ? "BijItem" : "border" : ''}
                        ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : (date.englsih_tithi_name === "Pancham" && date.paksha_type === "Sud") ? "PanchamItem" : "border"}
                        ${this.state.getTithe == "12" ? (date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : date.englsih_tithi_name === "Agiyaras" ? "GyarasItem" : "border" : ''}
                        ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : date.englsih_tithi_name === "Chaudas" ? "ChaudasItem" : "border"}
                        ${this.state.getTithe == "12" ? (date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : date.englsih_tithi_name === "Punam" ? "PoonamItem" : "border" : ''}
                        ${this.state.getTithe == "12" ? (date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayItem" : date.englsih_tithi_name === "Amas" ? "PoonamItem" : "border" : ''}`} >
                    {
                        isEmpty ? null : (
                            <div className="position-relative">
                                <h6 className={` ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "prakshNamePOSToday" : "prakshNamePOS"}
                                ${date.englsih_tithi_name === "Atham" ? "pakshaNameText" : null}
                                ${date.englsih_tithi_name === "Chaudas" ? "pakshaNameText" : null} 
                                ${(date.englsih_tithi_name === "Pancham" && date.paksha_type === "Sud") ? "pakshaNameText" : null}
                                `}>
                                    {props.t(date.paksha_type)}
                                </h6>
                                <h6 className={`dateText ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayText" : null}
                                ${date.englsih_tithi_name === "Atham" ? "todayTextPacham" : null}
                                ${date.englsih_tithi_name === "Chaudas" ? "todayTextPacham" : null} 
                                ${(date.englsih_tithi_name === "Pancham" && date.paksha_type === "Sud") ? "todayTextPacham" : null}
                                `}>{this.state.selectedCalendarType == "Hindu" ? props.t(date.tithi
                                ) : props.t(date.day?.toString())}</h6>
                                {this.state.selectedCalendarType == "Hindu" ?
                                    <>
                                        <h6 className={`${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayNameText" : "dayNameText"} 
                                        ${date.englsih_tithi_name === "Atham" ? "PanchamItemText" : null}
                                        ${date.englsih_tithi_name === "Chaudas" ? "PanchamItemText" : null} 
                                        ${(date.englsih_tithi_name === "Pancham" && date.paksha_type === "Sud") ? "PanchamItemText" : null}
                                        `}>
                                            {props.t(date.day?.toString()) + "/" + props.t(date.month)}
                                        </h6>
                                    </> :
                                    <h6 className={` ${(date?.day?.toString() === today && date?.month?.toString() === month && date?.year?.toString() === year) ? "todayNameText" : "dayNameText"} 
                                    ${date.englsih_tithi_name === "Atham" ? "PanchamItemText" : null}
                                    ${date.englsih_tithi_name === "Chaudas" ? "PanchamItemText" : null} 
                                    ${(date.englsih_tithi_name === "Pancham" && date.paksha_type === "Sud") ? "PanchamItemText" : null}`}>
                                        {this.state.selectedLanguage === "English" && date.englsih_tithi_name}
                                        {this.state.selectedLanguage === "Gujarati" && date.gujarati_tithi_name}
                                        {this.state.selectedLanguage === "Hindi" && date.hindi_tithi_name}
                                    </h6>
                                }
                            </div>
                        )
                    }
                </div >
            )
        };
        const monthDataHindi = [
            { value: 1, label: this.props.t('કારતક'), englabel: this.props.t('January') },
            { value: 2, label: this.props.t('માગશર'), englabel: this.props.t('February') },
            { value: 3, label: this.props.t('પોષ'), englabel: this.props.t('March') },
            { value: 4, label: this.props.t('મહા'), englabel: this.props.t('April') },
            { value: 5, label: this.props.t('ફાગણ'), englabel: this.props.t('May') },
            { value: 6, label: this.props.t('ચૈત્ર'), englabel: this.props.t('June') },
            { value: 7, label: this.props.t('વૈશાખ'), englabel: this.props.t('July') },
            { value: 8, label: this.props.t('જેઠ'), englabel: this.props.t('August') },
            { value: 9, label: this.props.t('અષાઢ'), englabel: this.props.t('September') },
            { value: 10, label: this.props.t('શ્રાવણ'), englabel: this.props.t('October') },
            { value: 11, label: this.props.t('ભાદરવો'), englabel: this.props.t('November') },
            { value: 12, label: this.props.t('આસો'), englabel: this.props.t('December') },
        ];
        const monthDataHindiGUJ = [
            { value: 1, label: this.props.t('કારતક') },
            { value: 2, label: this.props.t('માગશર') },
            { value: 3, label: this.props.t('પોષ') },
            { value: 4, label: this.props.t('મહા') },
            { value: 5, label: this.props.t('ફાગણ') },
            { value: 6, label: this.props.t('ચૈત્ર') },
            { value: 7, label: this.props.t('વૈશાખ') },
            { value: 8, label: this.props.t('જેઠ') },
            { value: 9, label: this.props.t('અષાઢ') },
            { value: 10, label: this.props.t('શ્રાવણ') },
            { value: 11, label: this.props.t('ભાદરવો') },
            { value: 12, label: this.props.t('આસો') },
        ]
        const monthDataHindiENG = [
            { value: 1, label: this.props.t('January') },
            { value: 2, label: this.props.t('February') },
            { value: 3, label: this.props.t('March') },
            { value: 4, label: this.props.t('April') },
            { value: 5, label: this.props.t('May') },
            { value: 6, label: this.props.t('June') },
            { value: 7, label: this.props.t('July') },
            { value: 8, label: this.props.t('August') },
            { value: 9, label: this.props.t('September') },
            { value: 10, label: this.props.t('October') },
            { value: 11, label: this.props.t('November') },
            { value: 12, label: this.props.t('December') },
        ]
        
        let HinduMonth = ""
        const match = this.state.selectedCalendarType == "Hindu" ? monthDataHindi.find(item => item.value == this.state.selectedMonthGuj) : monthDataHindi.find(item => item.value == this.state.selectedMonth)
        if (match) {
            HinduMonth = this.state.selectedCalendarType == "Hindu" ? match.label : match.englabel
        }


        const optionMonthEnglish = [
            { label: this.props.t("January"), value: 1 },
            { label: this.props.t("February"), value: 2 },
            { label: this.props.t("March"), value: 3 },
            { label: this.props.t("April"), value: 4 },
            { label: this.props.t("May"), value: 5 },
            { label: this.props.t("June"), value: 6 },
            { label: this.props.t("July"), value: 7 },
            { label: this.props.t("August"), value: 8 },
            { label: this.props.t("September"), value: 9 },
            { label: this.props.t("October"), value: 10 },
            { label: this.props.t("November"), value: 11 },
            { label: this.props.t("December"), value: 12 }
        ]

        return (
            <React.Fragment>
                <Loader isLoading={this.state.isLoading} />
                <div>
                    <Modal isOpen={this.state.isDateSelected} toggle={() => this.isSelectedCloseModal()} size="lg" centered={true}>
                        <ModalHeader>
                            Please Select Month and Year
                        </ModalHeader>
                        <ModalBody className="py-1 px-2">
                            <Row>
                                <div className="row ast_bottompadder30" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <Col lg="5">
                                        <div className="mb-3">
                                            <Label className="form-label"> Select Month</Label>
                                            <Select
                                                value={this.state.selectedCalendarType == "Hindu" ? monthDataHindiGUJ.find(option => option.value === this.state.selectedMonthGuj) : monthDataHindiENG.find(option => option.value === this.state.selectedEnglishMonth)}
                                                onChange={(e) => {
                                                    // this.getExtranotes(this.state.currentPage, e.value, this.state.selectedEnglishYear)
                                                    this.setState({ selectedEnglishMonth: e.value })
                                                }}
                                                // options={monthDataHindi}
                                                options={this.state.selectedCalendarType == "Hindu" ? monthDataHindiGUJ : monthDataHindiENG}
                                                styles={{ zindex: 99 }}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className="mb-3">
                                            <Label className="form-label">Select Year</Label>
                                            {
                                                this.state.selectedCalendarType == "Hindu" ? (
                                                    <Select
                                                        value={this.state.yearHindiData.find(option => option.value == this.state.selectedHinduYear)}
                                                        onChange={(e) => {
                                                            // this.getExtranotes(this.state.currentPage,this.state.selectedEnglishMonth, e.value)
                                                            this.setState({ selectedHinduYear: e.value })
                                                        }}
                                                        options={this.state.yearHindiData}
                                                        styles={{ zindex: 99 }}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                ) : (
                                                    <Select
                                                        value={this.state.yearData.find(option => option.value == this.state.selectedEnglishYear)}
                                                        onChange={(e) => {
                                                            // this.getExtranotes(this.state.currentPage,this.state.selectedEnglishMonth, e.value)
                                                            this.setState({ selectedEnglishYear: e.value })
                                                        }}
                                                        options={this.state.yearData}
                                                        styles={{ zindex: 99 }}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                )
                                            }


                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <div style={{ textAlign: 'end', }}>
                                            <a onClick={() => {
                                                // if (this.state.selectedEnglishYear == yearName) {
                                                //     const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
                                                //     const filteredData = panchkalyanakLocale?.extranotes_data.filter(item => {
                                                //         const [itemYear, itemMonth] = item.date_calendar.split('-');
                                                //         return itemYear == this.state.selectedEnglishYear && parseInt(itemMonth, 10) == parseInt(this.state.selectedEnglishMonth, 10);
                                                //     });
                                                //     this.setState({ extranotesData: filteredData })
                                                // } else {
                                                //     this.getExtranotes(this.state.currentPage, this.state.selectedEnglishMonth, this.state.selectedEnglishYear)
                                                // }
                                                this.state.selectedCalendarType == "Hindu" ? this.getCalender("", this.state.selectedEnglishMonth, this.state.selectedMonthGuj, this.state.selectedCalendarType, this.state.selectedHinduYear):
                                                this.getCalender(this.state.selectedEnglishYear, this.state.selectedEnglishMonth, this.state.selectedMonthGuj, this.state.selectedCalendarType, this.state.selectedHinduYear);
                                            }} className="ast_btn mt-2" style={{ height: '39px', lineHeight: '36px' }}>Submit</a>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </ModalBody>
                    </Modal>
                </div>
                <div>
                    <Modal isOpen={this.state.selectedDateDataModal} toggle={() => this.closeModal()} size="lg" centered={true}>
                        <ModalHeader>{`${this.props.t(this.state.selectedDateData.day)}/${this.props.t(this.state.selectedDateData.month)}/${this.props.t(this.state.selectedDateData.year)}`} [{this.state.selectedLanguage === "English" && this.state.selectedDateData.guj_month_english_name}
                            {this.state.selectedLanguage === "Gujarati" && this.state.selectedDateData.guj_month_gujarati_name}
                            {this.state.selectedLanguage === "Hindi" && this.state.selectedDateData.guj_month_hindi_name}-{this.props.t(this.state.selectedDateData.paksha_type)}-
                            {this.state.selectedLanguage === "English" && this.state.selectedDateData.englsih_tithi_name}
                            {this.state.selectedLanguage === "Gujarati" && this.state.selectedDateData.gujarati_tithi_name}
                            {this.state.selectedLanguage === "Hindi" && this.state.selectedDateData.hindi_tithi_name}]</ModalHeader>
                        <ModalBody className="py-1 px-1">
                            <Row>
                                <Col lg={12}>
                                    {this.state.selectedDateData?.panchkalyanak_data &&
                                        <Accordion open={this.state.selectedDateData?.panchkalyanak_data?.length > 0 && this.state.isOpen} toggle={this.toggle}>
                                            <AccordionItem>
                                                <AccordionHeader className={this.state.selectedDateData?.panchkalyanak_data?.length > 0 ? "isvaluetrue" : "isvaluefalse"} targetId={"1"} >
                                                    {this.props.t(`Panch Kalyan`)}</AccordionHeader>
                                                <AccordionBody accordionId={"1"}>
                                                    <div style={{ borderColor: '#000000', borderWidth: 0.5, padding: 3, margin: 3, backgroundColor: '#fff' }}>
                                                        {this.state.selectedDateData?.panchkalyanak_data?.length < 1 ? <>
                                                            <p style={{ color: '#000' }}>{this.props.t(`No Data Found From Panchkalyanak!`)}</p>
                                                        </> : <>
                                                            {this.state.selectedDateData?.panchkalyanak_data?.map((item, index) => {
                                                                return (
                                                                    <p key={index} style={{ color: '#000' }}>
                                                                        {this.props.t(item.kalyanak_god_name)} [{this.props.t(item.kalyanak_type)}]
                                                                    </p>
                                                                )
                                                            })
                                                            }
                                                        </>}
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    }
                                    {this.state.selectedDateData?.jainfestival_data &&
                                        <Accordion open={this.state.selectedDateData?.jainfestival_data?.length > 0 && this.state.isOpen} toggle={this.toggle}>
                                            <AccordionItem>
                                                <AccordionHeader className={this.state.selectedDateData?.jainfestival_data?.length > 0 ? "isvaluetrue" : "isvaluefalse"} targetId={"2"}>
                                                    {this.props.t(`Jain Festivals`)}</AccordionHeader>
                                                <AccordionBody accordionId={"2"}>
                                                    <div style={{ borderColor: '#000000', borderWidth: 0.5, padding: 3, margin: 3, backgroundColor: '#fff' }}>
                                                        {this.state.selectedDateData?.jainfestival_data?.length < 1 ? <>
                                                            <p style={{ color: '#000' }}>{this.props.t(`No Data Found From Jain Festival!`)}</p>
                                                        </> : <>
                                                            {this.state.selectedDateData?.jainfestival_data?.map((item, index) => {
                                                                return (
                                                                    <p key={index} style={{ color: '#000' }}>
                                                                        {this.state.selectedLanguage === "English" && item.festival_name}
                                                                        {this.state.selectedLanguage === "Gujarati" && item.festival_name_gujarati}
                                                                        {this.state.selectedLanguage === "Hindi" && item.festival_name_hindi}
                                                                    </p>
                                                                )
                                                            })
                                                            }
                                                        </>}
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    }
                                    {this.state.selectedDateData?.extranotes_data &&
                                        <Accordion open={this.state.selectedDateData?.extranotes_data?.length > 0 && this.state.isOpen} toggle={this.toggle}>
                                            <AccordionItem>
                                                <AccordionHeader className={this.state.selectedDateData?.extranotes_data?.length > 0 ? "isvaluetrue" : "isvaluefalse"} targetId={"3"}>
                                                    {this.props.t(`Extra Notes`)}</AccordionHeader>
                                                <AccordionBody accordionId={"3"}>
                                                    <div style={{ borderColor: '#000000', borderWidth: 0.5, padding: 3, margin: 3, backgroundColor: '#fff' }}>
                                                        {this.state.selectedDateData?.extranotes_data?.length < 1 ? <>
                                                            <p style={{ color: '#000' }}>{this.props.t(`No Data Found From Extra Notes!`)}</p>
                                                        </> : <>
                                                            {this.state.selectedDateData?.extranotes_data?.map((item, index) => {
                                                                return (
                                                                    <p key={index} style={{ color: '#000' }}>
                                                                        {this.state.selectedLanguage === "English" && item.notes}
                                                                        {this.state.selectedLanguage === "Gujarati" && item.notes_gujarati}
                                                                        {this.state.selectedLanguage === "Hindi" && item.notes_hindi}
                                                                    </p>
                                                                )
                                                            })
                                                            }
                                                        </>}
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    }
                                    {/* {(!this.state.selectedDateData?.panchkalyanak_data?.length > 0 && !this.state.selectedDateData?.jainfestival_data?.length > 0 && !this.state.selectedDateData?.extranotes_data?.length > 0) &&
                                        <div style={{ justifyContent: 'center', width: "100%", height: 150, textAlign: 'center', display: 'flex' }}>
                                            <h2 style={{ margin: 'auto' }}>{this.props.t(`No Data Found !`)}</h2>
                                        </div>
                                    } */}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-center mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-warning btn-lg me-2"
                                            onClick={() => this.closeModal()}
                                        >
                                            Okay
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </div>

                <div className="ast_about_wrapper ast_toppadder20 ast_bottompadder20">
                    <div className="container" style={{ height: "auto", }}>
                        {/* <div style={{alignItems :'center' , border:'1px solid red' , display:'flex' , justifyContent :'center'}}>
                            <ChartSection />
                        </div> */}
                        <div>
                            <Row>
                                <Col lg="12">
                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                                        <div>
                                            {/* <ProgressBar dataP={this.props.t}/> */}
                                            {Latitude && Longitude && language && <ProgressBar dataP={this.props.t} />}
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col lg="12" style={{ zIndex: 10 }}>
                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '15px'}}>
                                        <GenerateCalendarPDF dataP={this.props.t} CalendarType={this.state.selectedCalendarType}/>
                                    </div>

                                </Col> */}
                            </Row>
                        </div>
                        <div style={{
                            backgroundColor: "#fff",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            marginBottom: 10,
                            
                        }}>
                            {/* 1st Arrow */}
                            <button
                                style={{
                                    backgroundColor: "#3b3483",
                                    width: "18%",
                                    borderRadius: 10,
                                    color: "#fff",
                                    zIndex: 10,
                                }}
                                onClick={() => {
                                    // this.getCalender(!this.state.selectedCalendarType=="Hindi"&&this.state.selectedYear - 1, 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.selectedMonthGuj == "12" ? (parseInt(this.state.vikram_samvat)) - 1 : this.state.vikram_samvat);

                                    //English 
                                    var temp_year = this.state.selectedYear
                                    var temp_month = this.state.selectedMonth
                                    var temp_month_gujarati = this.state.selectedMonthGuj
                                    var temp_vikaramsamvat = this.state.vikram_samvat
                                    var temp_type = this.state.selectedCalendarType
                                    // temp_type=this.state.selectedCalendarType
                                    if (this.state.selectedCalendarType == "Hindu") {
                                        temp_vikaramsamvat = this.state.vikram_samvat - 1
                                        temp_month_gujarati = 1
                                         this.setState({vikram_samvat : parseInt(this.state.vikram_samvat) - 1})
                                        // temp_month = 1

                                        //this.getCalender(this.state.selectedYear - 1, 12, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    } else {
                                        temp_year = this.state.selectedYear - 1
                                        temp_month = 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth - 1, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    }

                                    this.getCalender(temp_year, temp_month, temp_month_gujarati, temp_type, temp_vikaramsamvat);
                                }}

                            >
                                <i className="fa fa-angle-double-left" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                            </button>
                            {/* 2nd Arrow */}
                            <button
                                style={{
                                    backgroundColor: "#3b3483",
                                    width: "18%",
                                    borderRadius: 10,
                                    color: "#fff",
                                    zIndex: 10,
                                }}
                                onClick={() => {
                                    //English 
                                    var temp_year = null
                                    var temp_month = null
                                    var temp_month_gujarati = null
                                    var temp_vikaramsamvat = null
                                    var temp_type = this.state.selectedCalendarType

                                    // temp_type=this.state.selectedCalendarType
                                    if (this.state.selectedMonth == 1) {
                                        temp_year = this.state.selectedYear - 1
                                        temp_month = 12

                                        //this.getCalender(this.state.selectedYear - 1, 12, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    } else {
                                        temp_year = this.state.selectedYear
                                        temp_month = this.state.selectedMonth - 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth - 1, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    }
                                    //Hindu
                                    if (this.state.selectedMonthGuj == "1") {

                                        temp_vikaramsamvat = parseInt(this.state.vikram_samvat) - 1
                                        temp_month_gujarati = 12
                                        this.setState({vikram_samvat : parseInt(this.state.vikram_samvat) - 1})
                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth, this.state.selectedCalendarType, parseInt(this.state.vikram_samvat) - 1);
                                    } else {

                                        temp_vikaramsamvat = this.state.vikram_samvat
                                        temp_month_gujarati = this.state.selectedMonthGuj - 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth - 1, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    }
                                    this.getCalender(temp_year, temp_month, temp_month_gujarati, temp_type, temp_vikaramsamvat);
                                }}
                            >
                                <i className="fa fa-angle-left" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                            </button>


                            {/* <button
                                style={{
                                    backgroundColor: "#3b3483",
                                    borderRadius: 10,
                                    fontSize: 25,
                                    color: "#fff"
                                }}
                            >
                                {this.state.selectedCalendarType === "Hindu" ? HinduMonth : this.props.t(this.state.selectedMonth)}
                            </button>
                            <button
                                style={{
                                    backgroundColor: "#3b3483",
                                    borderRadius: 10,
                                    fontSize: 25,
                                    color: "#fff"
                                }}
                            >
                                {this.props.t(this.state.selectedYear)}
                            </button> */}

                            <div
                                style={{ width: "16%", backgroundColor: '#3b3483', borderRadius: 10, textAlign: 'center', cursor: "pointer", zIndex: 10, }}
                                onClick={() => this.setState({ isDateSelected: true })}
                            >
                                {/* <span className="month_year">{this.state.selectedCalendarType === "Hindu" ? HinduMonth : this.props.t(this.state.selectedMonth)} / {this.props.t(this.state.selectedYear)}</span> */}
                                <span className="month_year">{this.state.selectedLanguage == "English" ? HinduMonth?.slice(0, 3) : HinduMonth} / {this.state.selectedCalendarType == "Hindu" ? this.props.t(this.state.vikram_samvat) : this.props.t(this.state.selectedYear)}</span>
                            </div>
                          
                            {/* 3th Arrow */}
                            <button
                                style={{
                                    backgroundColor: "#3b3483",
                                    width: "18%",
                                    borderRadius: 10,
                                    color: "#fff",
                                    zIndex: 10,
                                }}


                                onClick={() => {
                                    //English 
                                    var temp_year = null
                                    var temp_month = null
                                    var temp_month_gujarati = null
                                    var temp_vikaramsamvat = null
                                    var temp_type = this.state.selectedCalendarType

                                    // temp_type=this.state.selectedCalendarType
                                    if (this.state.selectedMonth == 12) {
                                        temp_year = this.state.selectedYear + 1
                                        temp_month = 1

                                        //this.getCalender(this.state.selectedYear - 1, 12, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    } else {
                                        temp_year = this.state.selectedYear
                                        temp_month = this.state.selectedMonth + 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth - 1, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    }
                                    //Hindu
                                    
                                    if (this.state.selectedMonthGuj == "12") {

                                        temp_vikaramsamvat = parseInt(this.state.vikram_samvat) + 1
                                        temp_month_gujarati = 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth, this.state.selectedCalendarType, parseInt(this.state.vikram_samvat) - 1);
                                    } else {

                                        temp_vikaramsamvat = this.state.vikram_samvat
                                        temp_month_gujarati = this.state.selectedMonthGuj + 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth - 1, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    }
                                    this.getCalender(temp_year, temp_month, temp_month_gujarati, temp_type, temp_vikaramsamvat);
                                }}



                            // onClick={() => {
                            //     if (this.state.selectedMonth == 12) {
                            //         console.log("true")
                            //         this.getCalender(this.state.selectedYear + 1, 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.vikram_samvat);
                            //     } else if (this.state.selectedMonthGuj == "12") {
                            //         this.getCalender(this.state.selectedYear, this.state.selectedMonth, this.state.month_in_gujarati, this.state.selectedCalendarType, parseInt(this.state.vikram_samvat) + 1);
                            //     }
                            //     else {
                            //         console.log("false")
                            //         this.getCalender(this.state.selectedYear, this.state.selectedMonth + 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.vikram_samvat);
                            //     }
                            // }}
                            >
                                <i className="fa fa-angle-right" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                            </button>
                            {/* 4th Arrow */}
                            <button
                                style={{
                                    backgroundColor: "#3b3483",
                                    width: "18%",
                                    borderRadius: 10,
                                    color: "#fff",
                                    zIndex: 10,
                                }}
                                // onClick={() => {
                                //     this.getCalender(this.state.selectedYear + 1, 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.selectedMonthGuj == "12" ? (parseInt(this.state.vikram_samvat)) + 1 : this.state.vikram_samvat);
                                // }}
                                onClick={() => {
                                    // this.getCalender(!this.state.selectedCalendarType=="Hindi"&&this.state.selectedYear - 1, 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.selectedMonthGuj == "12" ? (parseInt(this.state.vikram_samvat)) - 1 : this.state.vikram_samvat);

                                    //English 
                                    var temp_year = this.state.selectedYear
                                    var temp_month = this.state.selectedMonth
                                    var temp_month_gujarati = this.state.selectedMonthGuj
                                    var temp_vikaramsamvat = this.state.vikram_samvat
                                    var temp_type = this.state.selectedCalendarType
                                    
                                    // temp_type=this.state.selectedCalendarType
                                    if (this.state.selectedCalendarType == "Hindu") {
                                        temp_vikaramsamvat = parseInt(this.state.vikram_samvat) + 1
                                        temp_month_gujarati = 1
                                        // temp_month = 1

                                        //this.getCalender(this.state.selectedYear - 1, 12, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    } else {
                                        temp_year = this.state.selectedYear + 1
                                        temp_month = 1

                                        // this.getCalender(this.state.selectedYear, this.state.selectedMonth - 1, this.state.selectedCalendarType, this.state.vikram_samvat);
                                    }
                                    this.getCalender(temp_year, temp_month, temp_month_gujarati, temp_type, temp_vikaramsamvat);
                                }}
                            >
                                <i className="fa fa-angle-double-right" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                            </button>
                        </div>
                        <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 10, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', }}>
                            <div className="customcontainer">
                                <div className="headerRow">
                                    {daysOfWeek.map((day, index) => {
                                        return (
                                            <div key={index} className="headerItem">
                                                <h6 className="headerText">{this.props.t(day?.slice(0, 3))}</h6>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="calendarGrid">
                                    {fullData.map((item, index) => {
                                        return (
                                            <CalendarItem key={index} date={item} isEmpty={!item.day_name} index={index} props={this.props} />
                                        )
                                    })}
                                </div>
                            </div>
                            {this.state.getTithe == "5" ? 
                            <>
                            <div style={{ justifyContent: 'space-evenly', display: 'flex', paddingTop: 20, textAlignLast: 'center' }}>
                                
                                <div style={{ backgroundColor: "#ffc09f", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Pancham')}</span>
                                </div>
                                <div style={{ backgroundColor: "#edd9c1", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Chaudas')}</span>
                                </div>
                                <div style={{ backgroundColor: "#d7e7b6", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Atham')}</span>
                                </div>
                            </div>
                            </> 
                            : 
                            <>
                            <div style={{ justifyContent: 'space-evenly', display: 'flex', paddingTop: 20, textAlignLast: 'center' }}>
                                
                                <div style={{ backgroundColor: "#ffc09f", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Pancham')}</span>
                                </div>
                                <div style={{ backgroundColor: "#edd9c1", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Chaudas')}</span>
                                </div>
                                <div style={{ backgroundColor: "#d7e7b6", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Atham')}</span>
                                </div>
                            </div>
                            <div style={{ justifyContent: 'space-evenly', display: 'flex', paddingTop: 10, textAlignLast: 'center' }}>
                                <div style={{ backgroundColor: "#c5d4e7", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Agiyaras')}</span>
                                </div>
                                <div style={{ backgroundColor: "#d5dedb", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Beej')}</span>
                                </div>
                                <div style={{ backgroundColor: "#f4eeb2", padding: 10, width: "32%" }}>
                                    <span style={{ color: "#000", fontWeight: "400" }}>{this.props.t('Punam')}/{this.props.t('Amas')}</span>
                                </div>
                            </div>
                            </>}
                            
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default withNamespaces()(Calender);

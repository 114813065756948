import React, { Component } from "react";
import { Col, Label, Row, Table } from "reactstrap";
import { withNamespaces } from "react-i18next";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import Loader from "../Loader";
import i18n from "../../../i18n";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL } from "../../../Global";


class Pachkhan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pachkhanData: [],
            selectedType: "morning",
            selectedLanguage: "English",
            isOpen: null,
            isLoading: false
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getPachkhan(this.state.selectedType)

        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
    }
    async getPachkhan(type) {
        this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")

        // console.log("Form data", JSON.stringify({
        //     "type": type,
        // }))

        try {
            await fetch(`${BASEURL}/getfrontpanchakhan`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "type": type,
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            this.setState({
                                pachkhanData: responseJson.data,
                                showLoader: false,
                                selectedType: type,
                                // currentPage: responseJson.meta.pagination.current_page,
                                // totalPages: responseJson.meta.pagination.total_pages,
                            })
                        } else {
                            toastr.warning("Something Went Wrong !!")

                            this.setState({ showLoader: false, pachkhanData: null })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }
    render() {
        const toggle = (id) => {
            if (this.state.isOpen === id) {
                this.setState({ isOpen: "" })
            } else {
                this.setState({ isOpen: id })
            }
        };
        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder40 bg-light" style={{ zIndex: 10, position: 'relative' }}>
                    <div className="container">

                        <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 15, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', }}>
                            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                <Col onClick={() => {
                                    this.setState({ selectedType: "morning" })
                                    this.getPachkhan("morning")
                                }} className="text-center mb-3 p-3 fw-bold " style={{ color: this.state.selectedType === "morning" ? "#fff" : "#000", backgroundColor: this.state.selectedType === "morning" ? "#3b3483" : "#d3d3d3" }}>{this.props.t(`Morning`)}</Col>
                                <Col onClick={() => {
                                    this.setState({ selectedType: "evening" })
                                    this.getPachkhan("evening")
                                }} className="text-center mb-3 p-3 fw-bold " style={{ color: this.state.selectedType === "evening" ? "#fff" : "#000", backgroundColor: this.state.selectedType === "evening" ? "#3b3483" : "#d3d3d3" }}>{this.props.t(`Evening`)}</Col>
                            </div>
                            {this.state.pachkhanData?.length > 0 ?
                                <>
                                    <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                        <Col onClick={() => {
                                            this.setState({ selectedLanguage: "English" })
                                        }} className="text-center mb-1 p-3 fw-bold " style={{ color: this.state.selectedLanguage === "English" ? "#fff" : "#000", borderTopLeftRadius: 10, borderBottomLeftRadius: 10, backgroundColor: this.state.selectedLanguage === "English" ? "#3b3483" : "#d3d3d3" }}>{this.props.t(`English`)}</Col>
                                        <Col onClick={() => {
                                            this.setState({ selectedLanguage: "Gujarati" })
                                        }} className="text-center mb-1 p-3 fw-bold " style={{ color: this.state.selectedLanguage === "Gujarati" ? "#fff" : "#000", backgroundColor: this.state.selectedLanguage === "Gujarati" ? "#3b3483" : "#d3d3d3", }}>{this.props.t(`Gujarati`)}</Col>
                                        <Col onClick={() => {
                                            this.setState({ selectedLanguage: "Hindi" })
                                        }} className="text-center mb-1 p-3 fw-bold " style={{ color: this.state.selectedLanguage === "Hindi" ? "#fff" : "#000", borderTopRightRadius: 10, borderBottomRightRadius: 10, backgroundColor: this.state.selectedLanguage === "Hindi" ? "#3b3483" : "#d3d3d3" }}>{this.props.t(`Hindi`)}</Col>
                                    </div>
                                    {this.state.selectedType === "morning" &&
                                        <>
                                            {this.state.pachkhanData?.map((item) => {
                                                return (
                                                    <Accordion open={this.state.isOpen} toggle={toggle}>
                                                        <AccordionItem>
                                                            <AccordionHeader targetId={item.id}>
                                                                {this.state.selectedLanguage === "English" && item.name_english}
                                                                {this.state.selectedLanguage === "Gujarati" && item.name_gujarati}
                                                                {this.state.selectedLanguage === "Hindi" && item.name_hindi}</AccordionHeader>
                                                            <AccordionBody accordionId={item.id}>
                                                                <p>{this.state.selectedLanguage === "English" && item.pachakhan_details.details_english}</p>
                                                                <p>{this.state.selectedLanguage === "Gujarati" && item.pachakhan_details.details_gujarati}</p>
                                                                <p>{this.state.selectedLanguage === "Hindi" && item.pachakhan_details.details_hindi}</p>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                    </Accordion>
                                                )
                                            })}
                                        </>
                                    }
                                    {this.state.selectedType === "evening" &&
                                        <>
                                            {this.state.pachkhanData?.map((item) => {
                                                return (
                                                    <Accordion open={this.state.isOpen} toggle={toggle}>
                                                        <AccordionItem>
                                                            <AccordionHeader targetId={item.id}>
                                                                {this.state.selectedLanguage === "English" && item.name_english}
                                                                {this.state.selectedLanguage === "Gujarati" && item.name_gujarati}
                                                                {this.state.selectedLanguage === "Hindi" && item.name_hindi}
                                                            </AccordionHeader>
                                                            <AccordionBody accordionId={item.id}>
                                                                <p>{this.state.selectedLanguage === "English" && item.pachakhan_details.details_english}</p>
                                                                <p>{this.state.selectedLanguage === "Gujarati" && item.pachakhan_details.details_gujarati}</p>
                                                                <p>{this.state.selectedLanguage === "Hindi" && item.pachakhan_details.details_hindi}</p>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                    </Accordion>
                                                )
                                            })}
                                        </>
                                    }
                                </> :
                                <div style={{ justifyContent: 'center', width: "100%", height: 150, textAlign: 'center', display: 'flex' }}>
                                    <h2 style={{ margin: 'auto' }}>{this.props.t(`No Data Found !`)}</h2>
                                </div>
                            }
                        </div>
                    </div >
                </div >
            </React.Fragment >
        );
    }
}

export default withNamespaces()(Pachkhan);
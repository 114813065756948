import React, { Component } from "react";
import Loader from "../Common/Loader";
import { BASEURL } from "../../Global";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            themeSettingData: [],
            scrolledPast300px: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.getThemeSetting()
        const isLogin = localStorage.getItem('authUserJain')
        if (isLogin === "true") {
            this.setState({ isLogin: true })
        } else {
            this.setState({ isLogin: false })
        }
    }

    handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY >= 150 && !this.state.scrolledPast300px) {
            this.setState({ scrolledPast300px: true });
        } else if (scrollY < 150 && this.state.scrolledPast300px) {
            this.setState({ scrolledPast300px: false });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    scrollTop() {
        window.scrollTo(0, 0);
    }
    async getThemeSetting() {
        this.setState({ isLoading: true })
        try {
            await fetch(`${BASEURL}/findthemesettingswithoutauth/NxOpZowo9GmjKqdR`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson) {
                            if (responseJson) {
                                if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                                    // this.props.history.push(process.env.PUBLIC_URL + '/login')
                                    localStorage.clear()
                                    window.location.href = process.env.PUBLIC_URL + "/login"
                                    return false;
                                }
                                this.setState({
                                    themeSettingData: responseJson.data,
                                    isLoading: false
                                })
                                // console.log("Theme Created", responseJson)
                            }
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isLoading} />
                <div className="ast_footer_wrapper ast_toppadder70 ast_bottompadder20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_footer_info">
                                    <img src={require('../../Assets/images/Logo/logo.png')} alt="Logo" />
                                    <p>Saral Jain Panchang, your guide through Jain auspicious events, provides an intuitive, ad-free experience with exact location-based pachkan timings, multilingual options, and insights into Rashi, Kalyanak, Festivals, and Other Jain events. Simplify your Jain calendar journey with Saral Jain Panchang.</p>
                                    <ul>
                                        <li><a href={this.state.themeSettingData?.facebook_link}><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a href={this.state.themeSettingData?.instagram_link}><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                        <li><a href={this.state.themeSettingData?.youtube_link}><i className="fa fa-youtube" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="widget text-widget">
                                    <h4 className="widget-title">About us</h4>
                                    <div className="ast_newsletter">
                                        <p>Saral Jain Panchang, your guide through Jain auspicious events, provides an intuitive, ad-free experience with exact location-based pachkan timings, multilingual options, and insights into Rashi, Kalyanak, Festivals, and Other Jain events. Simplify your Jain calendar journey with Saral Jain Panchang.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                                <div className="widget text-widget">
                                    <h4 className="widget-title">our services</h4>
                                    <div className="ast_servicelink">
                                        <ul>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/navkarshi" : process.env.PUBLIC_URL + "/login"}>Navkarshi</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/pachkhan" : process.env.PUBLIC_URL + "/login"}>Pachkhan</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/choghadiya" : process.env.PUBLIC_URL + "/login"}>Choghadiya</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/panchkalyanak" : process.env.PUBLIC_URL + "/login"}>Panch Kalyanak</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/jainfestival" : process.env.PUBLIC_URL + "/login"}>Jain Festival</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/extranotes" : process.env.PUBLIC_URL + "/login"}>Extra notes</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/suryarashi" : process.env.PUBLIC_URL + "/login"}>Surya Rashi</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/chandrarashi" : process.env.PUBLIC_URL + "/login"}>Chandra Rashi</a></li>
                                            <li><a href={this.state.isLogin ? process.env.PUBLIC_URL + "/hora" : process.env.PUBLIC_URL + "/login"}>Hora</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                                <div className="widget text-widget">
                                    <h4 className="widget-title">quick links</h4>
                                    <div className="ast_sociallink">
                                        <ul>
                                            <li><a href={process.env.PUBLIC_URL + "/features"}>features</a></li>
                                            <li><a href={process.env.PUBLIC_URL + "/about"}>about</a></li>
                                            <li><a href={process.env.PUBLIC_URL + "/contactus"}>contact us</a></li>
                                            <li><a href={process.env.PUBLIC_URL + "/term-condition"}>term & condition</a></li>
                                            <li><a href={process.env.PUBLIC_URL + "/privacy-policy"}>privacy policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="widget text-widget">
                                    <h4 className="widget-title">get in touch</h4>
                                    <div className="ast_gettouch">
                                        <ul>
                                            <li><i className="fa fa-home" aria-hidden="true"></i> <p>{this.state.themeSettingData?.address}</p></li>
                                            <li><i className="fa fa-at" aria-hidden="true"></i> <a href={`mailto: ${this.state.themeSettingData?.email}`}>{this.state.themeSettingData?.email}</a></li>
                                            <li><i className="fa fa-phone" aria-hidden="true"></i> <p>{this.state.themeSettingData?.mobile}</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_copyright_wrapper">
                                    <p>&copy; Copyright {new Date().getFullYear()}, All Rights Reserved, <a href={"https://saraljainpanchang.com/"}>Saral Jain Panchang</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.scrolledPast300px && <button onClick={() => this.scrollTop()} className="myBtn" title="Go to top"><i className="fa fa-chevron-up" aria-hidden="true"></i></button>}
            </React.Fragment>
        );
    }
}

export default Footer;

import React, { Component } from "react";
import Header from "../../Pages/Header/Header";
import Footer from "../../Pages/Footer/Footer";
import Services from "../../Pages/Common/Services";
import Download from "../../Pages/Common/Download";
import MainHeader from "../../Pages/Common/Mainheader"
import Breadcrumb from "../../Pages/Common/Breadcrumb";
import Calender from "../../Pages/Calender/Calender";
import { getcountrycode } from "../../Global";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: null,
            longitude: null,
            error: null,
        };
    }

    componentDidMount() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.handleSuccess,
                this.handleError
            );
        } else {
            this.setState({ error: 'Geolocation is not supported by your browser' });
        }
    }

    handleSuccess = async (position) => {
        const { latitude, longitude } = position.coords;
        this.setState({ latitude, longitude });
        const con = await getcountrycode()

        if (latitude && longitude) {
            localStorage.setItem("saralLongitude", longitude)
            localStorage.setItem("saralLatitude", latitude)
            localStorage.setItem("saralCountry", con.countrycode)
        }
    };

    handleError = (error) => {
        this.setState({ error: error.message });
    };

    render() {
        return (
            <React.Fragment>
                <Header />
                <MainHeader />
                {/* <ChartSection /> */}
                <Breadcrumb title="User Dashboard" />
                <Calender />
                <Services />
                <Download />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Home;

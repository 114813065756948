import React, { Component } from "react";
import Loader from "../Common/Loader";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL } from "../../Global";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            subject: '',
            message: '',
            isLoading: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 500);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone_number") {
            const value = e.target.value.replace(/\D/g, '');
            const truncatedValue = value.slice(0, 10);
            this.setState({ [name]: truncatedValue });
        } else {
            // console.log("name", name, "valeee", value)
            this.setState({ [name]: value });
        }
    };



    handleSubmit = async () => {
        const { first_name, last_name, email, phone_number, subject, message } = this.state;
        this.setState({ isLoading: true })
        try {
            await fetch(`${BASEURL}/createcontactleads`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "name": first_name + last_name,
                        "email": email,
                        "mobile": phone_number,
                        "subject": subject,
                        "message": message
                    })
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            await toastr.success("We have Received Your Message..")
                            this.setState({
                                isLoading: false,
                                first_name: "",
                                last_name: "",
                                email: "",
                                phone_number: "",
                                subject: "",
                                message: "",
                            })
                        } else {
                            toastr.error("Something Went Wrong !!")
                            this.setState({ isLoading: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    };

    async validation() {
        if (this.state.first_name?.trim().length < 1) {
            toastr.warning('Enter First Name');
        }
        else if (this.state.last_name?.trim().length < 1) {
            toastr.warning('Enter Last Name');
        }
        else if (this.state.email?.trim().length < 1) {
            toastr.warning('Enter Valid Email');
        }
        else if (this.state.phone_number?.trim().length < 1) {
            toastr.warning('Enter valid Phone Number');
        } else if (this.state.phone_number.length < 10) {
            toastr.warning('Enter valid 10 Digit Phone Number');
        }
        else if (this.state.subject?.trim().length < 1) {
            toastr.warning('Enter Subject');
        }
        else if (this.state.message?.trim().length < 1) {
            toastr.warning('Enter Message');
        } else {
            this.handleSubmit()
        }
    }

    render() {
        return (
            <React.Fragment>
                <Loader isLoading={this.state.isLoading} />
                <div className="ast_mapnform_wrapper ast_toppadder70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="ast_heading">
                                    <h1>find & message <span>here</span></h1>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected hummer.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ast_contact_map">
                        <div className="ast_contact_form">
                            <form>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label>first name<span className="text-danger"> * </span></label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="require"
                                            value={this.state.first_name}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label>last name<span className="text-danger"> * </span></label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={this.state.last_name}
                                            onChange={this.handleChange}
                                            className="require" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label>email<span className="text-danger"> * </span></label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            className="require" data-valid="email" data-error="Email should be valid." />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label>phone number<span className="text-danger"> * </span></label>
                                        <input
                                            type="number"
                                            name="phone_number"
                                            value={this.state.phone_number}
                                            onChange={this.handleChange}
                                            className="require" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <label>subject<span className="text-danger"> * </span></label>
                                        <input
                                            type="text"
                                            name="subject"
                                            value={this.state.subject}
                                            onChange={this.handleChange}
                                            className="require" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <label>message<span className="text-danger"> * </span></label>
                                        <textarea
                                            rows="5"
                                            name="message"
                                            className="require"
                                            value={this.state.message}
                                            onChange={this.handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="response"></div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <button className="ast_btn pull-right submitForm" type="button" form-type="contact" onClick={() => this.validation()}>send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1835.1393309254122!2d72.58721298888085!3d23.086892794782198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83bfd5dd46e9%3A0xee3d70a62ba323f3!2sSatyanarayan%20Society%2C%20Ambica%20Nagar%2C%20Sabarmati%2C%20Ahmedabad%2C%20Gujarat%20380005!5e0!3m2!1sen!2sin!4v1703840025619!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactForm;

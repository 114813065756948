import React, { Component } from "react";
import Flatpickr from "react-flatpickr"
import { Col } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import "../../../Components/css/navkarshi.css"
import { withNamespaces } from "react-i18next";
import Loader from "../Loader";
import i18n from "../../../i18n";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL, getcountrycode } from "../../../Global";

class Hora extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputDate: new Date(),
            horaData: [],
            currentPage: 1,
            totalPages: 1,
            isLoading: false

        };
        this.interval = null;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        var day = new Date();
        var hr = day.getHours();
        // var hr = 19
        if (hr >= 7 && hr <= 15) {
            this.getHora(1, this.state.inputDate)
        } else if (hr > 15 && hr <= 23) {
            this.getHora(2, this.state.inputDate)
        } else {
            this.getHora(3, this.state.inputDate)
        }

        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
        this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds
    }
    async getHora(page, date) {
        // console.log("date", date, this.state.inputDate)
        this.setState({ showLoader: true, inputDate: date })
        const Token = await localStorage.getItem("authToken")
        const Longitude = await localStorage.getItem("saralLongitude")
        const Latitude = await localStorage.getItem("saralLatitude")
        const Country = await localStorage.getItem("saralCountry")
        const options = { weekday: 'long' };

        const year = new Date(`${date}`).getFullYear();
        const month = new Date(`${date}`).getMonth() + 1;
        const day = new Date(`${date}`).getDate();
        const weekday = new Date(`${date}`).toLocaleDateString('en-US', options);

        // console.log("Form data", JSON.stringify({
        //     "week_name": weekday.toString().split(",")[0],
        //     "latitude": Latitude,
        //     "longitude": Longitude,
        //     "country_code": Country,
        //     "day": day.toString(),
        //     "month": month.toString(),
        //     "year": year.toString()
        // }))

        // console.log("date", date, this.state.inputDate)

        try {
            await fetch(`${BASEURL}/getfronthoras?page=${page}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "week_name": weekday.toString().split(",")[0],
                        "latitude": Latitude,
                        "longitude": Longitude,
                        "country_code": Country,
                        "day": day.toString(),
                        "month": month.toString(),
                        "year": year.toString()
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            this.setState({
                                horaData: responseJson.data,
                                showLoader: false,
                                selectedDate: date,
                                currentPage: responseJson.meta.pagination.current_page,
                                totalPages: responseJson.meta.pagination.total_pages,
                            })
                        } else {
                            toastr.warning("Something Went Wrong !!")

                            this.setState({ showLoader: false, horaData: null })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }
    render() {
        return (
            <React.Fragment>

                <Loader isLoading={this.state.showLoader} />
                <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder40 bg-light">
                    <div className="container">
                        <div className="row ast_bottompadder30" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Col onClick={() => this.getHora(this.state.currentPage, new Date(new Date(this.state.inputDate).setDate(this.state.inputDate.getDate() - 1)))} sm="auto" style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, backgroundColor: "#3b3483", color: "#fff" }}>
                                <div className="form-group my-2 arrowleft">
                                    <i className="fa fa-chevron-left" style={{ fontSize: 32, marginTop: 3.5, }}></i>
                                </div>
                            </Col>
                            <Col sm="auto" style={{ backgroundColor: "#fff" }}>
                                <div className="form-group my-2">
                                    <div className="input-group">
                                        <Flatpickr
                                            key={this.state.inputDate}
                                            value={this.state.inputDate}
                                            onChange={(e) => {
                                                this.setState({ inputDate: new Date(`${e}`) })
                                                this.getHora(this.state.currentPage, new Date(`${e}`))
                                            }}
                                            className="form-control d-block text-center"
                                            placeholder="dd M,yyyy"
                                            options={{
                                                dateFormat: "d-m-Y"
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm="auto" onClick={() => this.getHora(this.state.currentPage, new Date(new Date(this.state.inputDate).setDate(this.state.inputDate.getDate() + 1)))} style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, backgroundColor: "#3b3483", color: "#fff", cursor: 'pointer' }}>
                                <div className="form-group my-2">
                                    <i className="fa fa-chevron-right" style={{ fontSize: 32, marginTop: 3.5, cursor: 'pointer' }}></i>
                                </div>
                            </Col>
                            <Col sm="auto" onClick={() => this.getHora(this.state.currentPage, new Date())} style={{ borderRadius: 10, backgroundColor: "#3b3483", color: "#fff", marginLeft: 10, cursor: 'pointer' }}>
                                <div className="form-group" style={{ marginBlockStart: 10, marginBlockEnd: 12 }}>
                                    <span className="mx-2" style={{ fontSize: 18, cursor: 'pointer' }}>{this.props.t(`Today`)}</span>
                                </div>
                            </Col>
                        </div>
                        <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 15, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', position: 'relative' }}>
                            <div className="row mx-2">
                                <Col className="text-center mb-3 p-3 fw-bold " style={{ backgroundColor: "#3b3483", color: "#fff" }}>{this.props.t(`Hora Name`)}</Col>
                                <Col className="text-center mb-3 p-3 fw-bold col-1" style={{ backgroundColor: "#3b3483", color: "#fff" }}>|</Col>
                                <Col className="text-center mb-3 p-3 fw-bold " style={{ backgroundColor: "#3b3483", color: "#fff" }}>{this.props.t(`Hora Time`)}</Col>
                            </div>
                            {this.state.horaData?.length > 0 ?
                                <>
                                    <>
                                        {this.state.horaData.map((item) => {

                                            const currentTime = `${this.state.selectedDate.getHours().toString().padStart(2, '0')}:${this.state.selectedDate.getMinutes().toString().padStart(2, '0')}`

                                            // const [startTime, endTime] = item.hora_time.split(" - ");
                                            const startTime = item.hora_time.split(" - ")[0];
                                            const endTime = item.hora_time.split(" - ")[1];


                                            if (new Date(`${this.state.selectedDate}`).toISOString().split("T")[0] == new Date().toISOString().split("T")[0]) {
                                                if (currentTime >= startTime && currentTime < endTime) {
                                                    item['isGreen'] = true
                                                    // this.setState({ isGreen: true })
                                                } else {
                                                    item['isGreen'] = false
                                                    // this.setState({ isGreen: false })
                                                }
                                            }

                                            const Time1 = this.props.t(`${item.hora_time?.trim()?.split("-")[0]?.trim()?.split(":")[0]}`)
                                            const Time2 = this.props.t(`${item.hora_time?.trim()?.split("-")[0]?.trim()?.split(":")[1]}`)
                                            const Time3 = this.props.t(`${item.hora_time?.trim()?.split("-")[1]?.trim()?.split(":")[0]}`)
                                            const Time4 = this.props.t(`${item.hora_time?.trim()?.split("-")[1]?.trim()?.split(":")[1]}`)

                                            return (
                                                <>
                                                    <div style={{ borderRadius: 10, borderWidth: 1, borderColor: "#000", boxShadow: '1px 2px 5px #d3d3d3', marginTop: 2, }} className="row mx-2 p-3">
                                                        <Col className="text-center fw-bold ">{this.state.selectedLanguage === "English" && item.name_english}
                                                            {this.state.selectedLanguage === "Gujarati" && item.name_gujarati}
                                                            {this.state.selectedLanguage === "Hindi" && item.name_hindi}</Col>
                                                        <Col className="text-center fw-bold col-1">|</Col>
                                                        <Col className="text-center fw-bold ">{Time1 + ":" + Time2 + " - " + Time3 + ":" + Time4}</Col>
                                                    </div>
                                                    {item.isGreen && (
                                                        // <div className="responsive-circle"
                                                        //     style={{
                                                        //         // height: 20,
                                                        //         // width: 20,
                                                        //         // backgroundColor: "green",
                                                        //         // borderRadius: 20,
                                                        //         // position: 'absolute',
                                                        //         // right: "10%",
                                                        //         // marginTop: "-5.2%",
                                                        //         // top: "16.5%"

                                                        //         // height: 20, 
                                                        //         // width: 20, 
                                                        //         // backgroundColor: 'green',
                                                        //         // borderRadius: '50%', 
                                                        //         // position: 'absolute',
                                                        //         // right: '10%',
                                                        //         // marginTop: "-5.2%",
                                                        //         // transform: 'translateY(-50%)', 
                                                        //     }}
                                                        // ></div>
                                                        <i className="responsive-circle fa fa-clock-o fs-3 text-success"></i>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </>
                                    <div className="row mt-3 mx-2">
                                        <Col onClick={() => {
                                            if (this.state.totalPages === this.state.currentPage) {
                                                this.setState({ currentPage: 1 })
                                                this.getHora(1, this.state.selectedDate)
                                            } else {
                                                this.getHora(this.state.currentPage + 1, this.state.selectedDate)
                                            }
                                        }} className="text-center mb-3 p-3 fw-bold" style={{ backgroundColor: "#3b3483", borderRadius: 10, cursor: 'pointer', color: "#fff" }}>{this.props.t("Click To Load More..")}</Col>
                                    </div>
                                </> :
                                <div style={{ justifyContent: 'center', width: "100%", height: 150, textAlign: 'center', display: 'flex' }}>
                                    <h2 style={{ margin: 'auto' }}>{this.props.t(`No Data Found !`)}</h2>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Hora);
